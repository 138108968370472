import axios from 'axios';

// local service
// const API_URL = 'http://localhost:8080'; // Replace with your API base URL

//prod service
const API_URL = 'https://api.admaps.ca';

////////////////////////////////////////////////////////////////////
//////////////////// USER //////////////////////////////////////////
////////////////////////////////////////////////////////////////////
const registerUser = async (email, password) => {
  const response = await axios.post(`${API_URL}/users/register`, {
    email,
    password,
  });
  return response.data;
};

const loginUser = async (email, password) => {
  const response = await axios.post(`${API_URL}/users/login`, {
    email,
    password,
  });
  return response.data;
};

const logoutUser = async () => {
  const response = await axios.post(`${API_URL}/users/logout`, null, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

const updateUser = async (userId, updatedUser) => {
  const response = await axios.put(`${API_URL}/${userId}`, updatedUser, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

const deleteUser = async (userId) => {
  const response = await axios.delete(`${API_URL}/${userId}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

////////////////////////////////////////////////////////////////////
//////////////////// LISTING //////////////////////////////////////////
////////////////////////////////////////////////////////////////////

const listAllListings = async () => {
    const response = await axios.get(`${API_URL}/listings`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const listAllByUser = async () => {
    const response = await axios.get(`${API_URL}/listings/user`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };

  const getListingDetails = async (listingId) => {
    console.log(listingId);
    console.log(`${API_URL}/listings/${listingId}`);
    const response = await axios.get(`${API_URL}/listings/${listingId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  
  const addListing = async (listing) => {
    console.log(listing);
    console.log(getToken());
    const response = await axios.post(`${API_URL}/listings`, listing, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const updateListing = async (listingId, updatedListing) => {
    const response = await axios.post(`${API_URL}/listings/${listingId}`, updatedListing, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const deleteListing = async (listingId) => {
    const response = await axios.delete(`${API_URL}/${listingId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };

  ////////////////////////////////////////////////////////////////////
  //////////////////// ORDERS //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  
  const listAllOrders = async () => {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const listAllOrdersByUser = async () => {
    const response = await axios.get(`${API_URL}/orders/user`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };

  const getOrderDetails = async (orderId) => {
    console.log(orderId);
    console.log(`${API_URL}/orders/${orderId}`);
    const response = await axios.get(`${API_URL}/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const addOrder = async (listingId) => {
    console.log(`${API_URL}/orders/add/${listingId}`);
    const response = await axios.post(
      `${API_URL}/orders/add/${listingId}`,
      {}, // Pass an empty object as the request body
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  };
  
  
  const updateOrder = async (orderId, updatedOrder) => {
    const response = await axios.put(`${API_URL}/${orderId}`, updatedOrder, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const deleteOrder = async (orderId) => {
    const response = await axios.delete(`${API_URL}/${orderId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };

  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    
    const response = await axios.post(`${API_URL}/orders/upload`, formData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });
    
    return response.data;
  };  
  ////////////////////////////////////////////////////////////////////
//////////////////// CAMPAIGNS /////////////////////////////////////
////////////////////////////////////////////////////////////////////

const listAllCampaigns = async () => {
    const response = await axios.get(`${API_URL}/campaigns`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const listAllCampaignsByUser = async () => {
    const response = await axios.get(`${API_URL}/campaigns/user`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const getCampaignDetails = async (campaignId) => {
    const response = await axios.get(`${API_URL}/campaigns/${campaignId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };

  const getOrdersByCampaign = async (campaignId) => {
    const response = await axios.get(`${API_URL}/campaigns/orders/${campaignId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const addCampaign = async (campaign) => {
    const response = await axios.post(`${API_URL}/campaigns`, campaign, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const updateCampaign = async (campaignId, updatedCampaign) => {
    const response = await axios.put(`${API_URL}/campaigns/${campaignId}`, updatedCampaign, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };
  
  const deleteCampaign = async (campaignId) => {
    const response = await axios.delete(`${API_URL}/campaigns/${campaignId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  };

  const addOrderIdToCampaign = async (campaignId, orderId) => {
    const response = await axios.post(`${API_URL}/campaigns/${campaignId}/orders`, JSON.stringify(orderId), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
        },
    });
    return response.data;
};

  const removeOrderIdFromCampaign = async (campaignId, orderId) => {
      const response = await axios.delete(`${API_URL}/campaigns/${campaignId}/orders/${orderId}`, {
          headers: {
              Authorization: `Bearer ${getToken()}`,
          },
      });
      return response.data;
  };
  
////////////////////////////////////////////////////////////  
///////////////      CHAT       ////////////////////  
////////////////////////////////////////////////////////////  

const getChatByOrder = async (orderId) => {
  const response = await axios.get(`${API_URL}/chat/orders/${orderId}`, {
      headers: {
          'Authorization': `Bearer ${getToken()}`,
      },
  });
  return response.data;
};

const addChatMessage = async (orderId, message) => {
  const chatMessageObject = {
    message: message
  };
  const response = await axios.post(`${API_URL}/chat/orders/${orderId}`, JSON.stringify(chatMessageObject), {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

////////////////////////////////////////////////////////////  
///////////////      ANALYTICS       ////////////////////  
//////////////////////////////////////////////////////////// 

const getAnalyticsData = async () => {
  const response = await axios.get(`${API_URL}/analytics`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

export const requestDemo = async (userInputs) => {
  console.log(userInputs);
  try {
    const response = await fetch('https://lxfw0w5ap4.execute-api.us-east-1.amazonaws.com/first/requestDemo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add other headers if necessary and allowed
      },
      body: JSON.stringify(userInputs)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Request failed:', error);
  }
};




const getToken = () => localStorage.getItem('token');

export {
    registerUser,
    loginUser,
    logoutUser,
    updateUser,
    deleteUser,
    getToken,
    listAllListings,
    listAllByUser,
    getListingDetails,
    addListing,
    updateListing,
    deleteListing,
    listAllOrders,
    getOrderDetails,
    listAllOrdersByUser,
    addOrder,
    updateOrder,
    deleteOrder,
    listAllCampaigns,
    listAllCampaignsByUser,
    getCampaignDetails,
    addCampaign,
    updateCampaign,
    deleteCampaign,
    getOrdersByCampaign,
    addOrderIdToCampaign,
    removeOrderIdFromCampaign,
    getChatByOrder,
    addChatMessage,
    getAnalyticsData,
    uploadImage
  };
  
  
