import React from 'react';
import { useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ open, handleClose, message, severity }) => {
    useEffect(() => {
    if (open) {
        toast[severity](message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 6000,
        onClose: handleClose,
        });
    }
}, [open, handleClose, message, severity]);

  return <ToastContainer />;
};

export default Toast;
