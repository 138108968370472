import React from 'react';
import { Grid, TextField, InputAdornment } from '@mui/material';
import {
  LocationOnOutlined as LocationOnIcon,
  HomeOutlined as HomeIcon,
  ApartmentOutlined as ApartmentIcon,
  MapOutlined as MapIcon,
  MailOutline as MailIcon,
} from '@mui/icons-material';

const LocationForm = ({ locationData, setLocationData }) => {
  const handleChange = (e) => {
    setLocationData({ ...locationData, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          name="streetName"
          label="Street Name"
          value={locationData.streetName}
          onChange={handleChange}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="streetAddress"
          label="Street Address"
          value={locationData.streetAddress}
          onChange={handleChange}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name="city"
          label="City"
          value={locationData.city}
          onChange={handleChange}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ApartmentIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name="province"
          label="Province"
          value={locationData.province}
          onChange={handleChange}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MapIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name="postalCode"
          label="Postal Code"
          value={locationData.postalCode}
          onChange={handleChange}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LocationForm;
