import React, { useState } from 'react';
import styled from 'styled-components';
import { requestDemo } from './api';
import { ModalBackground, ModalCard, Title, Form, Input} from './AuthModal';
import { Button1 } from '../screens/FrontPage';
import CloseIcon from '@mui/icons-material/Close';
import { css } from "@emotion/react";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading spinner


export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute; // Position absolute relative to ModalCard
  top: 10px;
  right: 10px;
  // Additional styling as required
`;

export const SuccessButton = styled.a`
  padding: 10px 20px;
  border: 2px solid #ED2647; // Orange border
  background-color: #fff;  // White background
  color: #ED2647; // Orange text
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  justify-self: center;
  border-radius: 12px;
  width: 200px;
  text-align: center; // Center text horizontally
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${props => props.requestStatus === 'success' && css`
    border-color: green;
    color: green;
    width: auto; // Adjust width for mobile
    padding: 10px; // Adjust padding if necessary
    &:hover {
      background-color: green;
      color: white;
    }
  `}
`;

export const BottomText = styled.p`
  margin-top: -10px;
  color: dimgrey;
  text-align: center;
`;

const FormPopup = ({ isVisible, onClose }) => {
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [requestStatus, setRequestStatus] = useState('idle');
  const [isLoading, setIsLoading] = useState(false); // New state for loading status

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form is submitted
    setRequestStatus('idle');
    const userInputs = { company, name, email, phone, selectedDate };

    try {
      await requestDemo(userInputs);
      setRequestStatus('success');
      setIsLoading(false); // Set loading to false when the request is completed
      setTimeout(onClose, 2000); // Close the modal after 2 seconds
    } catch (error) {
      console.error('Error submitting data:', error);
      setRequestStatus('failure');
      setIsLoading(false); // Set loading to false if there is an error
    }
  };

  return (
    <ModalBackground open={isVisible}>
      <ModalCard>
        <Title>Request a Demo</Title>
        <StyledCloseIcon onClick={onClose} />
        <Form onSubmit={handleSubmit}>
          <Input type="text" value={company} onChange={e => setCompany(e.target.value)} placeholder="Company" />
          <Input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
          <Input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email Address" />
          <Input type="tel" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone Number (optional)" />
          <BottomText>
            Expect an email response within 24 hours
          </BottomText>
          <SuccessButton type="submit" onClick={(e)=>handleSubmit(e)} requestStatus={requestStatus}>
            {isLoading ? <CircularProgress size={24} /> : requestStatus === 'success' ? 'Success!' : requestStatus === 'failure' ? 'Failed to send' : 'Submit'}
          </SuccessButton>
        </Form>
      </ModalCard>
    </ModalBackground>
  );
};

export default FormPopup;