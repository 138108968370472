import React, { useState } from 'react';
import { Paper, Typography, Select, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import {
  LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, PieChart, Pie, Cell
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']; // You can add more colors for pie chart sectors

const StyledWidget = styled(Paper)`
  padding: 20px;
  margin: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Widget = ({ isLoading, title, data, dataKey, type }) => {
  const [days, setDays] = useState(1);

  const handleChange = (event) => {
    setDays(event.target.value);
  };

  const renderChart = () => {
    switch (type) {
      case 'line':
        return (
          <LineChart width={400} height={200} data={data}>
            <Line type="monotone" dataKey={dataKey} stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </LineChart>
        );
      case 'bar':
        return (
          <BarChart width={400} height={200} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey={dataKey} fill="#8884d8" />
          </BarChart>
        );
      case 'pie':
        return (
          <PieChart width={400} height={200}>
            <Pie dataKey={dataKey} data={data} cx={200} cy={100} outerRadius={80} fill="#8884d8">
              {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
            </Pie>
            <Tooltip />
          </PieChart>
        );
      default:
        return null;
    }
  };

  return (
    <StyledWidget>
      <Typography variant="h6">{title}</Typography>
      <Select value={days} onChange={handleChange}>
        <MenuItem value={1}>1 day</MenuItem>
        <MenuItem value={7}>7 days</MenuItem>
        <MenuItem value={10}>10 days</MenuItem>
        <MenuItem value={30}>30 days</MenuItem>
        <MenuItem value={60}>60 days</MenuItem>
        <MenuItem value={90}>90 days</MenuItem>
        <MenuItem value="custom">Custom</MenuItem>
      </Select>
      <ChartContainer>
        {renderChart()}
      </ChartContainer>
    </StyledWidget>
  );
};

export default Widget;