const colors = {
    primary: {
      hex: '#FF5858',
      rgb: 'rgb(255,88,88)',
      cmyk: '0 65 65 0',
      pantone: '17-1656',
    },
    secondary: {
      hex: '#ED2647',
      rgb: 'rgb(237,38,71)',
      cmyk: '0 84 70 7',
      pantone: '18-1762',
    },
    accent: {
      hex: '#ED2647',
      rgb: 'rgb(237,38,71)',
      cmyk: '0 84 70 7',
      pantone: '18-1762',
    },
    background: {
      hex: '#FFFFFF',
      rgb: 'rgb(255,255,255)',
      cmyk: '0 0 0 0',
      pantone: '11-0601',
    },
  };
  
  export default colors;
  