import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import styled from "styled-components";

const ListingBoxStyled = styled(Paper)`
  padding: 1rem;
  @media (max-width: 768px) { // Adjust for mobile screens
    display: flex;
    align-content: center;
  }
`;

const ListingBox = ({ children }, myAds) => (
  <ListingBoxStyled myAds={myAds} elevation={3}>{children}</ListingBoxStyled>
);

export default ListingBox;