import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageUploadModal from '../components/ImageUploadModal';
import DateTimePickerList from '../components/DateTimePickerList';
import LocationForm from '../components/LocationForm';
import AddListing, { addListing } from '../components/api'
import Toast from '../components/Toast';
import RequirementsFromCustomers from '../components/RequirementsFromCustomers';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
}));

const categories = [
  'Billboards',
  'Transit',
  'Radio',
  'Social Media',
  'Email Marketing',
  'Direct Mail',
  'Guerrilla Marketing',
  'Ambient',
  'Sponsorships',
  'Events',
  'Product Placement',
];

const testAvail = [
  {
      "start": "2023-04-20T19:21:56.000Z",
      "end": "2023-04-30T22:33:04.000Z"
  }
]

const PostAd = () => {
  const classes = useStyles();
  const [title, setTitle] = useState('Billboard on highway');
  const [description, setDescription] = useState('A 12x12 billboard on a busy highway, just off the countly line. No indecent content, no smoking content. Minimum time 12 days ');
  const [price, setPrice] = useState('200');
  const [setUpFee, setSetUpFee] = useState('349.99');
  const [reachPerHour, setReachPerHour] = useState('3100');
  const [imageUrl, setImageUrl] = useState();
  const [category, setCategory] = useState();
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState('2d');
  const [availability, setAvailability] = useState();
  const [locationData, setLocationData] = useState({
    streetName: 'Leisure Ave',
    streetAddress: '156',
    city: 'Fredericton',
    province: 'NB',
    postalCode: 'E3B9T2',
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [imageUrls, setImageUrls] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const adData = {
      title,
      description,
      pricePerHour: price,
      reachPerHour,
      imageUrls: imageUrls,
      locationData,
      category,
      setUpFee,
      estimatedDeliveryTime,
      availability
    };

    try {
      await addListing(adData);
      handleOpen('success', 'Ad posted successfully');
    } catch (error) {
      handleOpen('error', 'An error occurred');
    }

    console.log('Ad data:', adData);
    // Add code to send the ad data to your server for processing
  };

  const handleOpen = useCallback((severity, message) => {
    setSeverity(severity);
    setMessage(message);
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  console.log(imageUrl);
  console.log(category);
  console.log(availability);

  return (
    <>
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h4" align="center">
        Create an Ad
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          required
        />
        <TextField
          label="Price per hour"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          type="number"
          required
        />
        <TextField
          label="Set up fee"
          value={setUpFee}
          onChange={(e) => setSetUpFee(e.target.value)}
          type="number"
          required
        />
        <TextField
          label="Est. Impressions per Hour"
          value={reachPerHour}
          onChange={(e) => setReachPerHour(e.target.value)}
          type="number"
          required
        />
        <LocationForm locationData={locationData} setLocationData={setLocationData} />
        <TextField
          label="Estimated delivery time"
          value={estimatedDeliveryTime}
          onChange={(e) => setEstimatedDeliveryTime(e.target.value)}
          required
        />
        <FormControl className={classes.formControl} required>
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
          </FormControl>
          <ImageUploadModal setImageUrls={setImageUrls} imageUrls={imageUrls} />
          <DateTimePickerList onChange={setAvailability} />
        <RequirementsFromCustomers category={category} setImageUrls={setImageUrls} imageUrls={imageUrls} />
        <Box
            display="flex" 
            justifyContent="space-between" 
            mt={3} 
            p={2} 
            bgcolor="background.paper"
            boxShadow={1}
          >
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Publish
            </Button>
            <Button variant="contained" color="Primary" type="submit">
              Archive
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Publish
            </Button>
          </Box>
         
      </form>
    </Container>
    <Toast open={open} handleClose={handleClose} message={message} severity={severity} />
        </>
  );
};

export default PostAd;