import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography, IconButton, Box, MenuItem, FormControl, Select } from '@mui/material';
import { FavoriteBorderOutlined, Share, Star } from '@mui/icons-material';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListingDetails from '../screens/ListingDetails';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import RFPForm from './FRPForm';
import { getListingDetails, getOrderDetails } from './api.js'
import ChatComponent from './ChatComponent';

const StatusSelect = styled(Select)`
  margin-left: 16px;
  min-width: 120px;
`;

const OrderDetails = () => {
  // const { id } = useParams();
  // const order = {/* fetch the order using the id */};
  // Using sample data for now
  // const order = {
  //   listing: {
  //     "id": "5e283cc6-6c2b-4571-94e3-165f0018ba2b",
  //     "title": "Prime Time Radio Ad",
  //     "description": "A radio ad spot during prime time hours.",
  //     "price": "3399.83",
  //     "reachPerHour": 65450,
  //     "imageUrl": "https://via.placeholder.com/100",
  //     "postedDate": "2023-03-18T03:10:06.368Z",
  //     "location": "Los Angeles, CA",
  //     "category": "Radio"
  //   },
  //   status: "Active",
  //   startDate: new Date(),
  //   endDate: new Date()
  // };

  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [listingData, setListingData] = useState();
  useEffect(() => {
    const fetchOrderDetails = async () => {
      console.log(id);
      const OrderData = await getOrderDetails(id);
      console.log(OrderData);
      if (OrderData[0] != undefined) {
        const ListingData = await getListingDetails(OrderData[0].listingId)
        setListingData(ListingData);
        setOrder(OrderData[0]);
      }

    };

    fetchOrderDetails();
  }, [id]);

  if (!order) {
    return <div>Loading...</div>;
  }
  console.log(order);



  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const isEditable = (status) => {
    return status === 'Submitted' || status === 'Drafts' || status === 'In Review';
  };

  const RFPBox = styled(Paper)`
  padding: 1rem;
  margin-top: 2rem;
`;
  return (
    <>
    <ListingDetails listingParam={listingData[0]} />
      <Container maxWidth="lg">
      </Container>
    </>
  );
};

export default OrderDetails;

