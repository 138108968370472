import * as React from 'react';
import styled from 'styled-components';
import PrimarySearchAppBar from '../components/appBar.js';
import { MyMap } from '../components/map.js'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { getAllAdRentalSpaces } from '../components/api.js';

export default function MapScreen() {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#1A2027',
        textAlign: 'center',
        color: 'blue',
      }));
    
    
    return(
    <>
        <CenterContainer>
            <MapContainer>
                <MyMap/>
            </MapContainer>
        </CenterContainer>
    </>
    )
}

const ListContainer = styled.div`
    height: 800px;
`;

const CenterContainer = styled.div`
    display: flex;
    height: 80%;
    width: 100%;
`;

const MapContainer = styled.div`
    z-index: -1
`;