import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Widget from '../components/Widget';
import { getAnalyticsData } from '../components/api'; 
import { ClipLoader } from 'react-spinners'; 
import { listAllOrdersByUser, getListingDetails } from '../components/api';
import moment from 'moment';

const AnalyticsScreen = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);  // to manage the loading state
  
    const [selectedStatus, setSelectedStatus] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [spendPerDayData, setSpendPerDayData] = useState([]);
    const [reachPerDayData, setReachPerDayData] = useState([]);
    
    const [sampleRows, setSampleRows] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const orders = await listAllOrdersByUser();
            const ordersWithListingDetails = await Promise.all(
              orders.map(async (order) => {
                const listingDetails = await getListingDetails(order.listingId);
                return { ...order, listingDetails };
              })
            );
    
            const calculatedSpendData = ordersWithListingDetails.reduce((acc, row) => {
              const duration = moment(row.endDate).diff(moment(row.startDate), 'hours');
              const totalCost = row.listingDetails.pricePerHour * duration;
          
              const date = moment(row.createdDate).format('YYYY-MM-DD');
          
              if (acc[date]) {
                acc[date].value += totalCost;
              } else {
                acc[date] = { name: date, value: totalCost };
              }
          
              return acc;
            }, {});
    
            const calculatedReachData = ordersWithListingDetails.reduce((acc, row) => {
              const duration = moment(row.endDate).diff(moment(row.startDate), 'hours');
              const totalReach = row.listingDetails.reachPerHour * duration;
          
              const date = moment(row.createdDate).format('YYYY-MM-DD');
          
              if (acc[date]) {
                acc[date].value += totalReach;
              } else {
                acc[date] = { name: date, value: totalReach };
              }
          
              return acc;
            }, {});
    
            setSpendPerDayData(Object.values(calculatedSpendData));
            setReachPerDayData(Object.values(calculatedReachData));
            setSampleRows(ordersWithListingDetails);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching orders and listing details:', error);
          }
        };
    
        fetchData();
    }, []);
  
    if (isLoading) {return <ClipLoader />}


  const dummyData = [
    { name: 'Day 1', value: 400 },
    { name: 'Day 2', value: 300 },
    { name: 'Day 3', value: 500 },
    // more data...
  ];

  const topAdsData = [
    { name: 'Ad 1', value: 400 },
    { name: 'Ad 2', value: 300 },
    { name: 'Ad 3', value: 500 },
    // more data...
  ];

  const topCategoriesData = [
    { name: 'Category 1', value: 400 },
    { name: 'Category 2', value: 300 },
    { name: 'Category 3', value: 500 },
    // more data...
  ];

  const averageImpressionsData = [
    { name: 'Ad 1', value: 400 },
    { name: 'Ad 2', value: 300 },
    { name: 'Ad 3', value: 500 },
    // more data...
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
      <Widget title="Spend per Day" data={spendPerDayData} dataKey="value" type="line" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Widget title="Reach per Day" data={reachPerDayData} dataKey="value" type="line" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Widget isLoading={isLoading} title="$ per Reach per Day" data={dummyData} dataKey="value" type="line" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Widget  title="Top Performing Ads" data={topAdsData} dataKey="value" type="bar" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Widget title="Top Performing Categories" data={topCategoriesData} dataKey="value" type="bar" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Widget  title="Average Impressions per Ad" data={averageImpressionsData} dataKey="value" type="pie" />
      </Grid>
    </Grid>
  );
};
    

export default AnalyticsScreen;
