import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Dialog, DialogContent, IconButton, Box, Grid } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { uploadImage } from './api';

const ImageUploadModal = ({ imageUrls, setImageUrls }) => {
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = useState([]);
  console.log(imageUrls);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    const urls = await Promise.all(acceptedFiles.map(async (file) => {
      const timestamp = new Date().getTime();
      const response = await uploadImage(file, timestamp);
      return response;
    }));
  
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setImageUrls((prevUrls) => [...prevUrls, ...urls]);
  }, []);  

  const removeFileAndUrl = (fileToRemove, urlToRemove) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
    setImageUrls((prevUrls) => prevUrls.filter(url => url !== urlToRemove));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: 25,
  });

  console.log(imageUrls);

  return (
    <div>
      <Box pb={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>Choose Pictures</Button>
      </Box>
      <Grid container spacing={2}>
        {imageUrls?.map((url, index) => (
          <Grid item xs={3} key={index}>
            <Box position="relative" width="100%">
              <img 
                src={url} 
                alt={`uploaded-thumbnail-${index}`} 
                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
              <IconButton 
                style={{ position: 'absolute', top: '5px', right: '5px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white' }} 
                size="small"
                onClick={() => removeFileAndUrl(files[index], url)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box {...getRootProps()} display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="2px dashed #ccc" borderRadius="8px" p={2} mb={2}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop images here, or click to select files (up to 25)</p>
          </Box>
          <Box>
            {files.map((file, index) => (
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} p={1} border="1px solid #ccc" borderRadius="8px">
                <p>{file.name}</p>
                <IconButton size="small" color="error" onClick={() => removeFileAndUrl(file, imageUrls[index])}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageUploadModal;
