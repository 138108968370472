import React from "react";
import styled from "styled-components";

const ListingImageStyled = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ccc;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
`;

const ListingImage = ({ imageUrl }) => {
  console.log(imageUrl);
  if (imageUrl == undefined) {
    return null;
  }
  const encodedUrl = imageUrl.replace(/\s/g, "%20");
  console.log(encodedUrl);
  return <ListingImageStyled imageUrl={encodedUrl} />;
};



export default ListingImage;
