import React from "react";
import { Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListingBox from "../components/ListingBox";
import ListingImage from "../components/ListingImage";

function ListingItem({ listing, pricePeriod, reachPeriod, myAds = false }) {
  const navigate = useNavigate();

  const handleListingClick = () => {
    navigate(`/listings/details/${listing.id}`, { state: { listing } });
  };

  const handleMyAdsListingClick = () => {
    navigate(`/myads/${listing.id}`, { state: { listing } });
  };
  console.log("myads: " + myAds);

  return (
    <ListingBox myAds={myAds}>
      <div onClick={myAds ? handleMyAdsListingClick : handleListingClick} style={{ cursor: "pointer" }}>
        <Grid container spacing={2}>
          <Grid item>
            <ListingImage imageUrl={listing.imageUrls[0]} />
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{listing.title}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {listing.location}
            </Typography>
            <Typography
              style={{
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                hyphens: 'auto',
              }}
            >
              {listing.description}
          </Typography>
            <Typography variant="body2" color="text.secondary" style={{width:'1000%'}}>
              Price per {pricePeriod}: ${parseFloat(listing.pricePerHour).toFixed(2)}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{width:'100%'}}>
              Est. Reach: per {reachPeriod}{" "}
              {listing.reachPerHour.toLocaleString()}
            </Typography>

          </Grid>
        </Grid>
      </div>
    </ListingBox>
  );
}

export default ListingItem;
