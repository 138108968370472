import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography, IconButton, Box, TextField, Select, MenuItem } from '@mui/material';
import { FavoriteBorderOutlined, Share, Star, Edit, Check, Clear, Close } from '@mui/icons-material';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { categoryNameToFilterValue, adCategories } from '../components/helperFunctions';
import { getListingDetails, updateListing, deleteListing } from '../components/api';
import ImageCarousel from "../components/ImageCarousel";
import ImageUploadModal from '../components/ImageUploadModal';
import AdStatusChip from '../components/AdStatusChip';


const Title = styled(Typography)`
  font-family: 'Manrope', sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

const GalleryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  border-radius: 8px;
  overflow: hidden;
`;


const GalleryImage = styled.img`
  width: calc(50% - 2px);
  height: 100%;
  object-fit: cover;
`;

const SmallImageContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 4px;
  margin-left: 4px;
  width: 50%;
  height: 100%;
`;

const SmallImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const EditableTextField = styled(TextField)`
  width: 100%;
`;

const EditIconButton = styled(IconButton)`
  padding: 0;
`;

const Button = styled.button`
  background-color: #1abc9c;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #024e99;
  }
`;

const MyAdScreen = () => {
  const { id } = useParams();
  const [listing, setListing] = useState(null);

    // Add new useState hooks for all fields
    const [editingTitle, setEditingTitle] = useState(false);
    const [editingDescription, setEditingDescription] = useState(false);
    const [editingLocation, setEditingLocation] = useState(false);
    const [editingPrice, setEditingPrice] = useState(false);
    const [editingReachPerHour, setEditingReachPerHour] = useState(false);
    const [editingPostedDate, setEditingPostedDate] = useState(false);
    const [editingCategory, setEditingCategory] = useState(false);
  
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [reachPerHour, setReachPerHour] = useState('');
    const [postedDate, setPostedDate] = useState('');
    const [category, setCategory] = useState('');  
    const [originalTitle, setOriginalTitle] = useState('');
    const [imageUrls, setImageUrls] = useState();
    const [originalStatus, setOriginalStatus] = useState();

  useEffect(() => {
    const fetchListing = async () => {
      const fetchedListing = await getListingDetails(id);
      console.log(fetchedListing);
      setListing(fetchedListing[0]);

      // Set initial values of state variables after fetching listing
      setTitle(fetchedListing[0].title);
      setLocation(fetchedListing[0].location);
      setDescription(fetchedListing[0].description);
      setPrice(fetchedListing[0].pricePerHour);
      setReachPerHour(fetchedListing[0].reachPerHour);
      setPostedDate(fetchedListing[0].createdDate);
      setCategory(fetchedListing[0].category);
      setOriginalTitle(fetchedListing[0].title);
      setOriginalStatus(fetchedListing[0].status);
    };
    fetchListing();
  }, [id]);

  const handleUpdateListing = async (field, value) => {
    console.log("trying to update!");
    const updatedListing = await updateListing(id, { ...listing, [field]: value });
    console.log(updatedListing);
    setListing(updatedListing);
  };

  const handleDeleteListing = async () => {
    await deleteListing(id);
  };

  const handleUpdateListingStatus = async (status) => {
    const updatedListing = await updateListing(id, { ...listing, "status": status });
    setOriginalStatus(updatedListing.status);
  };

  // Functions for handling editing
  const handleEditClick = (setEditing) => {
    setEditing(true);
  };

  const handleSaveClick = (setEditing) => {
    setEditing(false);
  };

  const handleCancelClick = (setEditing) => {
    setEditing(false);
  };

  const handleCancelTitleClick = () => {
    setTitle(originalTitle); // Reset the title to the original value
    setEditingTitle(false); // Stop editing the title
  };
  

  if (!listing) {
    return <div>Loading...</div>;
  }

  console.log(title);

  console.log(originalStatus);
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2} ml={1}>
            {editingTitle ? (
                <>
                <EditableTextField value={title} onChange={(e) => setTitle(e.target.value)} />
                <Check fontSize="small" onClick={() => setEditingTitle(false)} />
                <Clear fontSize="small" onClick={handleCancelTitleClick} />
                </>
            ) : (
                <>
                <Title variant="h4">{title}</Title>
                <EditIconButton onClick={() => setEditingTitle(true)}>
                    <Edit fontSize="small" />
                </EditIconButton>
                <AdStatusChip status={originalStatus} />

                </>
            )}
            </Box>

          {/* Other components remain the same */}
        </Grid>
        <Grid item xs={12}>
          {/* Add onClick event to images */}
          <GalleryContainer>
            <ImageCarousel imageUrls={listing.imageUrls} />
          </GalleryContainer>
          <Grid item xs={12}>
          <ImageUploadModal setImageUrls={setImageUrls} imageUrls={imageUrls} />
      </Grid>
      <Box mt={4} mb={4}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Description
          </Typography>
          <EditableField field="description" value={description} setValue={setDescription} editing={editingDescription} setEditing={setEditingDescription} handleUpdateListing={handleUpdateListing} />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Price per hour
          </Typography>
          <EditableField field="price" value={price} setValue={setPrice} editing={editingPrice} setEditing={setEditingPrice} handleUpdateListing={handleUpdateListing} />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Reach per Hour
          </Typography>
          <EditableField field="reachPerHour" value={reachPerHour} setValue={setReachPerHour} editing={editingReachPerHour} setEditing={setEditingReachPerHour} handleUpdateListing={handleUpdateListing} />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Posted Date
          </Typography>
          <EditableField field="postedDate" value={postedDate} setValue={setPostedDate} editing={editingPostedDate} setEditing={setEditingPostedDate} handleUpdateListing={handleUpdateListing} />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Location
          </Typography>
          <EditableField field="location" value={location} setValue={setLocation} editing={editingLocation} setEditing={setEditingLocation} handleUpdateListing={handleUpdateListing} />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Category
          </Typography>
          <EditableCategoryField value={category} setValue={setCategory} editing={editingCategory} setEditing={setEditingCategory} handleUpdateListing={handleUpdateListing}/>
        </Paper>
      </Box>

      <Grid item xs={12}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Button onClick={() => handleUpdateListingStatus(originalStatus === "ACTIVE" ? "DISABLED" : "ACTIVE")}>
          {originalStatus === "ACTIVE" ? "Disable" : "Publish"}
        </Button>
        <Button onClick={handleDeleteListing}>Delete</Button>
        <Button onClick={() => handleUpdateListingStatus("ARCHIVED")}>Archive</Button>
      </Box>

</Grid>


      </Grid>
    </Grid>
  </Container>
);
};

const EditableCategoryField = ({ value, setValue, editing, setEditing, handleUpdateListing }) => {
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    // handleUpdateListing();
    setEditing(false);
  };

  if (editing) {
    return (
      <Box display="flex" alignItems="center">
        <Select value={value} onChange={handleChange}>
          {adCategories.map((category) => (
            <MenuItem key={category} value={categoryNameToFilterValue(category)}>
              {categoryNameToFilterValue(category)}
            </MenuItem>
          ))}
        </Select>
        <Clear fontSize="small" onClick={handleCancelClick} />
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center">
        <Typography>{value}</Typography>
        <EditIconButton onClick={handleEditClick}>
          <Edit fontSize="small" />
        </EditIconButton>
      </Box>
    );
  }
};


const EditableField = ({ field, value, setValue, editing, setEditing, handleUpdateListing }) => {
  const [initialValue, setInitialValue] = useState(value);
  // console.log(handleUpdateListing);
    const handleEditClick = () => {
      console.log("editing");
      setInitialValue(value);
      setEditing(true);
    };
  
    const handleCancelClick = () => {
      console.log("canceling")
      setValue(initialValue);
      setEditing(false);
    };

    const handleSaveClick = () => {
      console.log("updating")
      handleUpdateListing(field, value)
      setEditing(false);
    }
    console.log(value);
    if (editing) {
      return (
        <Box display="flex" alignItems="center">
          <EditableTextField value={value} onChange={(e) => setValue(e.target.value)} />
          <Check fontSize="small" onClick={handleSaveClick} />
          <Clear fontSize="small" onClick={handleCancelClick} />
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          <Typography>{value}</Typography>
          <EditIconButton onClick={handleEditClick}>
            <Edit fontSize="small" />
          </EditIconButton>
        </Box>
      );
    }
  };
  

export default MyAdScreen;

