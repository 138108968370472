import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PaginationRounded from "../components/pagination";
import FilterSection from "../components/FilterSection";
import ListingsSection from "../components/ListingsSection";
import { createDummyData, adCategories, categoryNameToFilterValue, filterListings, getSortedData } from '../components/helperFunctions';
import ListingItem from "../components/ListingItem";
import { loginUser, logoutUser, listAllListings } from '../components/api';
import LoadingSpinner from '../components/LoadingSpinner';
import Toast from '../components/Toast';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  gap: 16px;

  @media (max-width: 768px) { 
    flex-wrap: wrap;
  }
`;



const NoListings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  gap: 1rem;
`;


const NoListingsImage = styled.img`
  width: 650px;
  height: auto;
`;


const NoListingsText = styled.h2`
  margin-top: 1rem;
`;

function Listings(searchTerm, setSearchTerm) {
  const [categoryFilter, setCategoryFilter] = useState('');
  const { pathname } = useLocation();
  const [sortOption, setSortOption] = useState('Recent');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(500000);
  const [minReach, setMinReach] = useState(0);
  const [maxReach, setMaxReach] = useState(10000000);
  const [reachPeriod, setReachPeriod] = useState('hour');
  const [pricePeriod, setPricePeriod] = useState('hour');
  const [locationFilter, setLocationFilter] = useState('All');
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  
  useEffect(() => {
    const pathCategory = pathname.split('/').pop();
    if (pathCategory && adCategories.includes(pathCategory)) {
      setCategoryFilter(pathCategory);
    } else {
      setCategoryFilter('All');
    }
  }, [pathname, adCategories]);
  
  useEffect(() => {
    const selectElement = document.getElementById("category-select");
    if (selectElement) {
      selectElement.value = categoryFilter;
    }
  }, [categoryFilter]);
  
  const [sampleRows, setSampleRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const rows = await listAllListings();
        setLocations(['All', ...new Set(rows.map(item => item.location))]);
        setSampleRows(rows);
      } catch (error) {
        console.error('Error fetching listings:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  console.log(sampleRows);

  const filteredListings = useMemo(() => {
    return filterListings(sampleRows, searchTerm, categoryFilter, minPrice, maxPrice, minReach, maxReach, reachPeriod, pricePeriod, locationFilter);
  }, [sampleRows, searchTerm, categoryFilter, reachPeriod, maxPrice, minPrice, minReach, maxReach, pricePeriod, locationFilter]);
  
  console.log(filteredListings);
  
  const sortedData = getSortedData(filteredListings, sortOption);
  const paginatedData = sampleRows// sortedData.slice(startIndex, endIndex);
  const hasListings = paginatedData.length > 0;

  return (
    <Container>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FilterSection
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            minReach={minReach}
            setMinReach={setMinReach}
            maxReach={maxReach}
            setMaxReach={setMaxReach}
            reachPeriod={reachPeriod}
            setReachPeriod={setReachPeriod}
            pricePeriod={pricePeriod}
            setPricePeriod={setPricePeriod}
            sortOption={sortOption}
            locationFilter={locationFilter}
            setLocationFilter={setLocationFilter}
            locations={locations}
          />
          {hasListings ? (
            <ListingsSection>
              {paginatedData.map((listing, index) => (
                <ListingItem
                  listing={listing}
                  pricePeriod={pricePeriod}
                  reachPeriod={reachPeriod}
                />
              ))}
              <div>
                Rows per page:
                <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </select>
              </div>
              <PaginationRounded
                page={page}
                onChange={handlePageChange}
                count={Math.ceil(sortedData.length / rowsPerPage)}
              />
            </ListingsSection>
          ) : (
            <NoListings>
             <NoListingsText>
                No listings found, try adjusting your filters
              </NoListingsText>
              <NoListingsImage
                src="https://cdn.midjourney.com/0fe02a15-282d-49c0-9c5a-f400fa958541/grid_0.png"
                alt="No listings found"
              />
            </NoListings>
          )}
        </>
      )}
    </Container>
  );
}  


export default Listings;
