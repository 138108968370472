import React, { useState } from 'react';
import { Typography, List, ListItem, Button, TextField, ListItemSecondaryAction, IconButton, Modal, Box } from '@material-ui/core';
import Select from 'react-select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {useMediaQuery} from "@mui/material";


const requirementTypes = [
  { value: 'text', label: 'Text' },
  { value: 'media', label: 'Media' },
  { value: 'number', label: 'Number' },
  { value: 'singleSelect', label: 'Single Select' },
  { value: 'multiSelect', label: 'Multi-Select' },
  { value: 'signature', label: 'Signature' },
  { value: 'boolean', label: 'Yes/no' }
];

const RequirementsFromCustomers = ({ category }) => {
  const initialRequirements = {
    'Billboards': [{value: 'Size of billboard', type: 'text'}, {value:'Duration of display', type: 'text'}, {value:'Preferred locations', type:'text'}],

  };

  const [requirements, setRequirements] = useState(initialRequirements[category] || []);
  const [newRequirement, setNewRequirement] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editText, setEditText] = useState('');
  const [requirementType, setRequirementType] = useState(requirementTypes[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addRequirement = () => {
    if (newRequirement) {
      let newRequirementWithType = {value: newRequirement, type: requirementType}


      setRequirements([...requirements, newRequirementWithType]);
      setNewRequirement('');
    }
  };

  const openModalForOptions = (index) => {
    setIsModalOpen(true);
  };

  const handleRequirementTypeChange = (selectedOption) => {
    setRequirementType(selectedOption);
    if (selectedOption.value === 'singleSelect' || selectedOption.value === 'multiSelect') {
      setIsModalOpen(true);
    }
  };

  const deleteRequirement = (index) => {
    const updatedRequirements = requirements.filter((_, i) => i !== index);
    setRequirements(updatedRequirements);
  };

  const startEdit = (index) => {
    setEditIndex(index);
    setEditText(requirements[index].value);
  };

  const cancelEdit = () => {
    setEditIndex(-1);
    setEditText('');
  };

  const saveEdit = () => {
    if (editText) {
      const updatedRequirements = requirements.map((item, i) =>
        i === editIndex ? { ...item, value: editText } : item
      );
      setRequirements(updatedRequirements);
      cancelEdit();
    }
  };


  const changeRowRequirement = (requirement, newRequirementType) => {
    console.log(requirement);
    console.log(newRequirementType); // new type

    const index = requirements.findIndex(r => r.value === requirement.value && r.type === requirement.type);
    if (index !== -1) {
      const updatedRequirement = { ...requirement, type: newRequirementType };

      // Create a copy of the requirements array and update it
      const updatedRequirements = [...requirements];
      updatedRequirements[index] = updatedRequirement;

      // Update the state
      setRequirements(updatedRequirements);
    } else {
      console.error('Requirement not found');
    }
  };

  const renderModal = () => {
    return (
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box p={2} style={{ backgroundColor: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Typography variant="h6">Options</Typography>
          {/* Add your form elements for options here */}
        </Box>
      </Modal>
    );
  };
  console.log(requirements);
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div>
      <Typography variant="h6">Requirements from Customers</Typography>
      <List>
        {requirements.map((requirement, index) => (
          <ListItem
            style={{
              width: editIndex === index ? '75%' : '50%',
              marginBottom: '5px'
            }}
            key={index}>
            {editIndex === index ? (
              <TextField
                multiline
                fullWidth
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                InputProps={{
                  style: {
                    overflow: 'auto',
                  },
                }}
                rows={3}
                variant="outlined"
              />

            ) : (
              isMobile ? `${requirement.value.substring(0, 17)}...` : requirement.value
            )}
            <ListItemSecondaryAction>
              {editIndex === index ? (
                <>
                  <IconButton onClick={saveEdit}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={cancelEdit}>
                    <EditIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton size={'small'}>
                    <Select
                      options={requirementTypes}
                      value={requirement.type}
                      onChange={(newValue) => changeRowRequirement(requirement, newValue)}
                      menuPortalTarget={document.body}

                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}                    />
                  </IconButton>
                  <IconButton onClick={() => startEdit(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteRequirement(index)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <TextField
        value={newRequirement}
        onChange={(e) => setNewRequirement(e.target.value)}
        placeholder="Add new requirement"
        fullWidth
      />
      <Select
        options={requirementTypes}
        value={requirementType}
        onChange={handleRequirementTypeChange}
      />
      <Button onClick={addRequirement}>Add</Button>
      {renderModal()}
    </div>
  );
};

export default RequirementsFromCustomers;

// placeholder={''}
// controlShouldRenderValue={!isMobile}
// 'Transit': ['Type of transit ad', 'Duration', 'Specific routes or areas'],
//   'Radio': ['Preferred time slots', 'Duration of ad', 'Specific stations or genres'],
//   'Social Media': ['Target platforms', 'Preferred posting times', 'Content style'],
//   'Email Marketing': ['Target audience', 'Frequency of emails', 'Type of content'],
//   'Direct Mail': ['Target area', 'Type of mail', 'Frequency of distribution'],
//   'Guerrilla Marketing': ['Type of activity', 'Locations', 'Target audience'],
//   'Ambient': ['Locations', 'Duration', 'Style of advertisement'],
//   'Sponsorships': ['Event type', 'Duration of sponsorship', 'Target audience'],
//   'Events': ['Event size', 'Target audience', 'Type of event'],
//   'Product Placement': ['Product type', 'Target platform', 'Duration of placement']