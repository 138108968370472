import React, { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@date-io/date-fns';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const DateTimePickerList = ({ onChange }) => {
  const [dateTimeRanges, setDateTimeRanges] = useState([{ start: null, end: null }]);
  const updateDateTimeRange = (index, start, end) => {
    const newDateTimeRanges = [...dateTimeRanges];
    newDateTimeRanges[index] = { start, end };
    setDateTimeRanges(newDateTimeRanges);
    onChange(newDateTimeRanges);
  };

  const addDateTimeRange = () => {
    setDateTimeRanges([...dateTimeRanges, { start: null, end: null }]);
  };

  const removeDateTimeRange = (index) => {
    const newDateTimeRanges = [...dateTimeRanges];
    newDateTimeRanges.splice(index, 1);
    setDateTimeRanges(newDateTimeRanges);
    onChange(newDateTimeRanges);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {dateTimeRanges.map(({ start, end }, index) => (
        <Box key={index} display="flex" alignItems="center" mb={2}>
          <DateTimePicker
            label="Start date and time"
            inputFormat="MM/dd/yyyy hh:mm a"
            value={start}
            onChange={(newValue) => updateDateTimeRange(index, newValue, end)}
            renderInput={(params) => <TextField {...params} fullWidth required />}
          />
          <DateTimePicker
            label="End date and time"
            inputFormat="MM/dd/yyyy hh:mm a"
            value={end}
            onChange={(newValue) => updateDateTimeRange(index, start, newValue)}
            renderInput={(params) => <TextField {...params} fullWidth required />}
          />
          {index === dateTimeRanges.length - 1 ? (
            <IconButton onClick={addDateTimeRange}>
              <AddCircleOutlineIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => removeDateTimeRange(index)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          )}
        </Box>
      ))}
    </LocalizationProvider>
  );
};

export default DateTimePickerList;