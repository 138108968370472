import { v4 as uuid } from "uuid";

export function createDummyData(num) {
    const categories = [
        { id: "billboards", name: "Billboards" },
        { id: "transit", name: "Transit" },
        { id: "radio", name: "Radio" },
        { id: "social_media", name: "Social Media" },
        { id: "email_marketing", name: "Email Marketing" },
        { id: "direct_mail", name: "Direct Mail" },
        { id: "guerrilla_marketing", name: "Guerrilla Marketing" },
        { id: "ambient", name: "Ambient" },
        { id: "sponsorships", name: "Sponsorships" },
        { id: "events", name: "Events" },
        { id: "product_placement", name: "Product Placement" },
        { id: "influencer_marketing", name: "Influencer Marketing" },
      ];
    
      const getRandomCategory = () => {
        return categories[Math.floor(Math.random() * categories.length)];
      };
    
      function getRandomTitleAndDescription(category) {
        const categoryData = {
          billboards: {
            title: "Highway Billboard",
            description: "Large billboard located near a busy highway.",
          },
          transit: {
            title: "Bus Stop Advertisement",
            description: "Advertisement space on a bus stop shelter.",
          },
          radio: {
            title: "Prime Time Radio Ad",
            description: "A radio ad spot during prime time hours.",
          },
          social_media: {
            title: "Sponsored Social Media Post",
            description: "A sponsored post on a popular social media platform.",
          },
          email_marketing: {
            title: "Targeted Email Campaign",
            description: "A targeted email marketing campaign to potential customers.",
          },
          direct_mail: {
            title: "Direct Mail Flyer",
            description: "A direct mail flyer sent to a specific audience.",
          },
          guerrilla_marketing: {
            title: "Creative Guerrilla Marketing",
            description: "A unique and creative guerrilla marketing campaign.",
          },
          ambient: {
            title: "Ambient Advertising Space",
            description: "Advertising space in a popular location with high foot traffic.",
          },
          sponsorships: {
            title: "Event Sponsorship",
            description: "Sponsorship opportunity for a popular event.",
          },
          events: {
            title: "Live Event Advertising",
            description: "Advertising space at a live event.",
          },
          product_placement: {
            title: "Product Placement in TV Show",
            description: "Product placement opportunity in a popular TV show.",
          },
          influencer_marketing: {
            title: "Influencer Marketing Campaign",
            description: "A marketing campaign featuring a popular influencer.",
          },
        };
      
        return categoryData[category.id] || {
          title: "Unknown Ad Space",
          description: "An unknown ad space. Please check the category.",
        };
      }
      
    
      const getRandomPrice = () => {
        return (Math.random() * (5000 - 50) + 50).toFixed(2);
      };
    
      const getRandomReach = () => {
        return Math.floor(Math.random() * (300000 - 100) + 100);
      };
    
      const getRandomDate = () => {
        const date = new Date();
        date.setDate(date.getDate() - Math.floor(Math.random() * 30));
        return date;
      };
    
      const getRandomLocation = () => {
        const locations = [
          "New York City, NY",
          "Los Angeles, CA",
          "Chicago, IL",
          "Houston, TX",
          "Phoenix, AZ",
          // ... add more locations
        ];
    
        return locations[Math.floor(Math.random() * locations.length)];
      };
    
      const getRandomActiveStatus = () => {
        return Math.random() < 0.5;
      };
    
      const sampleData = Array.from({ length: num }, () => {
        const category = getRandomCategory();
        const { title, description } = getRandomTitleAndDescription(category);
        const imageUrl = "https://via.placeholder.com/100";
        const price = getRandomPrice();
        const reachPerHour = getRandomReach();
        const postedDate = getRandomDate();
        const location = getRandomLocation();
        const id = uuid();
        const active = getRandomActiveStatus();
    
        return {
          id,
          title,
          description,
          price,
          reachPerHour,
          imageUrl,
          postedDate,
          location,
          category: category.name,
          active, // Added the active property
        };
      });
    
      return sampleData;
}

export function filterListings (
  listings,
  searchTerm,
  categoryFilter,
  minPrice,
  maxPrice,
  minReach,
  maxReach,
  reachPeriod,
  pricePeriod,
  locationFilter
) {
  const reachMultiplier = {
    hour: 1,
    day: 24,
    week: 24 * 7,
    month: 24 * 30
  };

  return listings.filter((item) => {
    const pricePerPeriod = Number(item.price) * reachMultiplier[pricePeriod];
    const priceInRange = (!minPrice || pricePerPeriod >= Number(minPrice)) && (!maxPrice || pricePerPeriod <= Number(maxPrice));
    const reachPerPeriod = Number(item.reachPerHour) * reachMultiplier[reachPeriod];
    const reachInRange = (!minReach || reachPerPeriod >= Number(minReach)) && (!maxReach || reachPerPeriod <= Number(maxReach));
    const categoryMatch = !categoryFilter || categoryFilter === 'All' || item.category === categoryNameToFilterValue(categoryFilter);
    const locationMatch = !locationFilter || locationFilter === 'All' || item.location === locationFilter;
    const searchMatch =
      searchTerm.searchTerm === '' ||
      item.title.toLowerCase().includes(searchTerm.searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.searchTerm.toLowerCase());

    return priceInRange && reachInRange && categoryMatch && locationMatch && searchMatch;

  });
};


export function categoryNameToFilterValue(name) {
  console.log(name);
  const categoryMap = {
    billboards: "Billboards",
    transit: "Transit",
    radio: "Radio",
    social_media: "Social Media",
    email_marketing: "Email Marketing",
    direct_mail: "Direct Mail",
    guerrilla_marketing: "Guerrilla Marketing",
    ambient: "Ambient",
    sponsorships: "Sponsorships",
    events: "Events",
    product_placement: "Product Placement",
    influencer_marketing: "Influencer Marketing"
  };

  return categoryMap[name] || '';
}

export const adCategories = [
  "billboards",
  "transit",
  "radio",
  "social_media",
  "email_marketing",
  "direct_mail",
  "guerrilla_marketing",
  "ambient",
  "sponsorships",
  "events",
  "product_placement",
  "influencer_marketing",
];

export function getSortedData (listingsData, sortOption) {
  if (!listingsData) {
    return [];
  }

  const sortedData = [...listingsData];
  switch (sortOption) {
    case "Price - Low":
      return sortedData.sort((a, b) => a.price - b.price);
    case "Price - High":
      return sortedData.sort((a, b) => b.price - a.price);
    case "Reach - Low":
      return sortedData.sort((a, b) => a.reach - b.reach);
    case "Reach - High":
      return sortedData.sort((a, b) => b.reach - a.reach);
    case "Recent":
    default:
      return sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
  }
};
