import React, { useState } from 'react';
import { useEffect } from "react";
import { getListingDetails, addOrder } from '../components/api.js'
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, IconButton, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@mui/material';
import { FavoriteBorderOutlined, Share, Star } from '@mui/icons-material';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { listAllCampaignsByUser, addOrderIdToCampaign } from '../components/api.js';
import ImageCarousel from "../components/ImageCarousel";


const Title = styled(Typography)`
  font-family: 'Manrope', sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

const GalleryContainer = styled(Box)`
  position: relative;
  max-height: 600px;
  border-radius: 8px;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;



const formatLocation = (location) => {
  if (!location) {
    return 'Location not available';
  }
  
  const { streetName, streetAddress, city, province, postalCode } = location;
  return `${streetName}, ${streetAddress}, ${city}, ${province}, ${postalCode}`;
};


export const ListingDetails = ({ listingParam = null }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [listing, setListing] = useState( listingParam ? listingParam : '');
  const [open, setOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const userCampaigns = await listAllCampaignsByUser();
        setCampaigns(userCampaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCampaignChange = (event) => {
    setSelectedCampaign(event.target.value);
  };

  const handleAddToCampaign = async () => {
    // Add logic here to add listing to the selected campaign
    console.log(id);
    let returnedOrderId = await addOrder(id);
    await addOrderIdToCampaign(selectedCampaign, returnedOrderId.id);
    console.log(`Adding order ${id} to campaign ${selectedCampaign}`);
    handleClose();
  };

  useEffect(() => {
    const fetchListingDetails = async () => {
      const listingData = await getListingDetails(id);
      console.log(listingData);
      setListing(listingData[0]);
    };

    if (!listingParam) {
      fetchListingDetails();
    } else {
      setListing(listingParam);
    }
  }, [id, listingParam]);
  useEffect(() => {
    const fetchListingDetails = async () => {
      const listingData = await getListingDetails(id);
      console.log(listingData);
      setListing(listingData[0]);
    };

  if (!listingParam) {
    fetchListingDetails();
  } else {
    setListing(listingParam);
  }
}, [id, listingParam]);


  if (!listing) {
    return <div>Loading...</div>;
  }

  const sampleImages = [
    "https://via.placeholder.com/100",
    "https://via.placeholder.com/200",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/400",
  ];

  console.log(listing);

  const handleBuy = async () => {
    const order = {
      // Add the necessary properties to create an order
      listingId: listing.id,
      // ...
    };

    try {
      const newOrder = await addOrder(listing.id);
      console.log(newOrder)
      navigate(`/order/${newOrder.id}`);
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };
  console.log(listing);
  console.log(campaigns);
  if (listing) {
    return (
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <div style={{ marginTop: '16px', marginLeft: '4px' }}>
            <Title variant="h4">{listing.title}</Title>
          </div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" ml={2}>
                  {listing.location}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
              </Box>
            </Box>
          </Grid>
          
          <GalleryContainer>
            <ImageCarousel imageUrls={listing.imageUrls} />
          </GalleryContainer>
          <Grid item xs={12}>

            <Box mt={4} mb={4}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography
                  style={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    hyphens: 'auto',
                  }}
                >
                  {listing.description}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Price per hour
              </Typography>
              <Typography>${listing.pricePerHour}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Reach per Hour
              </Typography>
              <Typography>{listing.reachPerHour}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Posted Date
              </Typography>
              <Typography>{new Date(listing.createdDate).toLocaleDateString()}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Location
              </Typography>
              <Typography>{formatLocation(listing.locationData)}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Category
              </Typography>
              <Typography>{listing.category}</Typography>
            </Paper>
          </Box>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleOpen} style={{backgroundColor: "#ED2647", paddingRight: "10px;"}}>
              Add to Campaign
            </Button>
          </Grid>
        </Grid>
        <Dialog onClose={handleClose} open={open}>
  <DialogTitle>
    Add to Campaign
    <Typography variant="subtitle2" color="textSecondary">
      A Campaign is a strategic effort to promote a specific goal. It consolidates multiple ads, allowing you to manage them effectively. 
    </Typography>
  </DialogTitle>
  <DialogContent>
    <FormControl fullWidth>
      <InputLabel style={{ marginTop: '2px' }}>Campaign</InputLabel>
        <Select
          value={selectedCampaign}
          onChange={handleCampaignChange}
        >
          {campaigns.map(campaign => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.title}
            </MenuItem>
          ))}
        </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button color="primary" onClick={() => navigate('/campaigns')}>
      Create New Campaign
    </Button>
    <Button onClick={handleClose} color="primary">
      Cancel
    </Button>
    <Button onClick={handleAddToCampaign} color="primary">
      Add
    </Button>
  </DialogActions>
</Dialog>
      </Container>
    );
  } else {
    return null;
  }
};

export default ListingDetails;
