import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select'

import {
  Paper,
  Typography,
  Box,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import PaginationRounded from '../components/pagination';
import RFPForm from '../components/FRPForm';
import { listAllOrdersByUser } from '../components/api';
import { getListingDetails } from '../components/api';
import ListingImage, { listingImage } from '../components/ListingImage'
const MyOrdersListingsSection = styled.div`
  display: ${(props) => (props.centerContent ? "flex" : "grid")};
  justify-content: ${(props) => (props.centerContent ? "center" : "unset")};
  align-items: ${(props) => (props.centerContent ? "center" : "unset")};
  flex-direction: ${(props) => (props.centerContent ? "column" : "unset")};
  grid-template-columns: ${(props) => (!props.centerContent ? "repeat(auto-fill, minmax(320px, 1fr))" : "unset")};
  grid-gap: 2rem;
  margin-bottom: 2rem;
  
  
`;

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-content: center;

  @media (max-width: 768px) { // Adjust for mobile screens
    width: 92%;
  }
`;

const Dropdown = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-left: 1rem;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%; /* Full width on small screens */
    font-size: 1.2rem; /* Larger font for easier readability */
    padding: 0.8rem; /* Slightly larger padding for better touch interaction */
    margin-left: 0; /* Adjust margin if necessary */
    margin-bottom: 1rem; /* Add some space below the dropdown */
  }
  
`;

const DropdownOption = styled.option`

  @media (max-width: 768px) {
    position: fixed; /* Adjusts the position to fixed */
    top: 50%; /* Centers it vertically */
    left: 50%; /* Centers it horizontally */
    transform: translate(-50%, -50%); /* Aligns the center of the dropdown with the center of the screen */
    width: 80%; /* Adjusts the width to be 80% of the screen */
    max-width: 400px; /* Ensures that the dropdown does not exceed a max width */
    font-size: 1.5rem; /* Increases font size for better readability */
    z-index: 1000; /* Ensures the dropdown appears above other content */
  }
  
`;

export const TitleContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; // Add this line
  min-width: 300px;
  max-width: 300px;
  margin-top: -2rem;

  @media (max-width: 768px) { // Adjust for mobile screens
    justify-content: center;
  }
`;

const OrderStatusFilter = styled(FormControl)`
  width: 100%;
  max-width: 300px;
  margin-left: 1rem;
`;


const OrderBox = styled(Paper)`
  padding: 1rem;
  margin-bottom: 1rem;
`;

const NoListings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  gap: 1rem;
`;


const NoListingsImage = styled.img`
  width: 650px;
  height: auto;
`;

export const StyledTitle = styled.h2`
`;

const NoListingsText = styled.h2`
  margin-top: 1rem;
`;
  
const NoOrders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  color: #333;
`;

function MyOrders() {
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const navigate = useNavigate();

  const [sampleRows, setSampleRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orders = await listAllOrdersByUser();
        const ordersWithListingDetails = await Promise.all(
          orders.map(async (order) => {
            console.log(order);
            console.log(order.listing.id) // Accessing listingId correctly
            const listingDetails = await getListingDetails(order.listing.id); // Using the correct listingId
            return { ...order, listingDetails };
          })
        );
        setSampleRows(ordersWithListingDetails);
      } catch (error) {
        console.error('Error fetching orders and listing details:', error);
      }
    };
  
    fetchData();
  }, []);
  
  
  

  console.log(sampleRows.length);


  const handleStatusChange = (event) => {

    setSelectedStatus(event.value);
    console.log(event.value);
    if (event.value === "ALL") {
      setFilteredOrders(sampleRows);
    } else {
      console.log(sampleRows);
      setFilteredOrders(
        sampleRows.filter((order) => order.status === event.value)
      );
    }
  };

  console.log(filteredOrders);

  useEffect(() => {
    if (selectedStatus === "All") {
      setFilteredOrders(sampleRows);
    } else {
      setFilteredOrders(
        sampleRows.filter((order) => order.status === selectedStatus)
      );
    }
  }, [sampleRows, selectedStatus]);

  console.log(sampleRows);

  const handleOpen = () => {
    navigate('/listings');
  }

  console.log(sampleRows)

  const options = [
    { value: 'All', label: 'All' },
    { value: 'ACTIVE', label: 'Active' },
    { value: 'REQUESTED', label: 'Requested' },
    { value: 'DRAFTS', label: 'Drafts' },
    { value: 'ARCHIVED', label: 'Archived' },
  ]

  const customStyles = {
    control: (base) => ({
      ...base,
      width: 150,
      marginTop:15,
    })
  };


  return (
    <Container>
      <TitleContainer>
        <StyledTitle>
          Status:&nbsp;
        </StyledTitle>
        <Select styles={customStyles} className="basic-single" defaultValue={options[0]} options={options} onChange={handleStatusChange}/>
      </TitleContainer>
      <MyOrdersListingsSection centerContent={filteredOrders.length === 0}>
      {filteredOrders.length > 0 ? filteredOrders.map((order) => (
        <Link to={`/order/${order.id}`} key={order.id} style={{ textDecoration: 'none' }}>
          <OrderBox key={order.id} elevation={3}>
            <Grid container spacing={2}>
              <Grid item>
              <ListingImage imageUrl={order.listing.imageUrls[0]} />
              </Grid>
              <Grid item xs>
                <Typography variant="h6">{order.title}</Typography>
                <Typography
              style={{
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                hyphens: 'auto',
                width: '400px'
              }}
            >                  {order?.listingDetails[0].description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${order?.listingDetails[0].pricePerHour}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Start Time: {order && order.startTime ? order.startTime : "Not set"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  End Time: {order && order.endTime ? order.endTime : "Not set"}
                </Typography>
                <Box mt={1}>
                  <Typography variant="body2" color="text.secondary">
                    Status: {order.status}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </OrderBox>
        </Link>
      ))
      : (
        <NoOrders>
        <Typography variant="h4" component="h2" gutterBottom>
          No Orders Yet
        </Typography>
        <Typography variant="body1" color="text.secondary">
          'My Orders' is your personalized section to manage all your advertising activities. You can browse and order advertisement opportunities, or post your own opportunities and have others order from you. It's a dynamic platform that caters to both your buying and selling needs in advertising.
        </Typography>
        <Button variant="contained" onClick={handleOpen} style={{backgroundColor: "#ED2647", marginTop: '2rem' }}>
          Start your first Order
        </Button>
      </NoOrders>
      
      )}
    </MyOrdersListingsSection>
    

      <PaginationRounded />
    </Container>
  );
}

export default MyOrders;
