import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import { Paper, Typography, Box, Grid, Fab, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PaginationRounded from '../components/pagination';
import { listAllCampaignsByUser, addCampaign } from '../components/api';

const CampaignsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 2rem;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CampaignCard = styled(Paper)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CampaignImage = styled.div`
  width: 100%;
  height: 200px;
  background-color: #ccc;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
`;

const NoCampaigns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  color: #333;
`;

const AddCampaignButton = styled(Fab)`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
`;

const NoCampaignsImage = styled.img`
  width: 650px;
  height: auto;
  margin: 0 auto;
`;

function CampaignsScreen() {
  const [campaigns, setCampaigns] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userCampaigns = await listAllCampaignsByUser();
        setCampaigns(userCampaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBrowse = () => {
    navigate('/listings');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const campaign = { title: title };
      const newCampaign = await addCampaign(campaign);
      setCampaigns([...campaigns, newCampaign]);
      setTitle('');
      handleClose();
    } catch (error) {
      console.error('Error adding campaign:', error);
    }
  };

  console.log(campaigns);
  

  return (
    <Container>
      <TitleContainer>
        <Typography variant="h5" component="h2" gutterBottom>
          My Campaigns
        </Typography>
        <Button variant="contained" onClick={handleOpen} style={{backgroundColor: "#ED2647"}}>
          Create Campaign
        </Button>
      </TitleContainer>
      {/*<Button variant="contained" color="primary" onClick={handleBrowse} style={{ width:"200px", marginTop: '-1rem', marginBottom: "1rem" }}>*/}
      {/*  Browse ad's to add to campaigns*/}
      {/*</Button>*/}
      {campaigns.length > 0 ? (
        <CampaignsSection>
          {campaigns.map((campaign) => (
            <Link to={`/campaigns/${campaign.id}`} key={campaign.id} style={{ textDecoration: 'none' }}>
              <CampaignCard elevation={3}>
                {/* <CampaignImage imageUrl={campaign.imageUrls[0]} /> */}
                <Typography variant="h6">{campaign.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Listings: {campaign.orderIds.length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Total Reach: {campaign.reachPerHour}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Total Price: {campaign.pricePerHour}
                </Typography>
              </CampaignCard>
            </Link>
          ))}
        </CampaignsSection>
      ) : (
        <NoCampaigns>
          <Typography variant="h4" component="h2" gutterBottom>
            No Campaigns Yet
          </Typography>
          <Typography variant="body1" color="text.secondary">
          Campaigns are consolidated groups of ads that streamline your advertising management. They not only allow you to organize your efforts for a holistic view of your ad's performance, but also simplify your tasks by aggregating common questions.           </Typography>
          <Button variant="contained" onClick={handleOpen} style={{backgroundColor: "#ED2647", marginTop: '2rem' }}>
            Start your first Campaign
          </Button>
        </NoCampaigns>
      )}
      <PaginationRounded />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create a new campaign</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Campaign Title"
              type="text"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default CampaignsScreen;