import { useInput } from "rooks";
import styled from "styled-components";

export default function NameForm(props) {
    const { value:firstName, bind:bindFirstName, reset:resetFirstName } = useInput('');
    const { value:lastName, bind:bindLastName, reset:resetLastName } = useInput('');
    
    const handleSubmit = (evt) => {
        evt.preventDefault();
        alert(`Submitting Name ${firstName} ${lastName}`);
        resetFirstName();
        resetLastName();
    }
    return (
      <CenterContainer>
        <div className="AdSpaceEntryForm-wrapper">
          <form onSubmit={handleSubmit}>
            <label>
              <p>First Name:</p>
              <input type="text" {...bindFirstName} />
            </label>
            <label>
              <p>Last Name:</p>
              <input type="text" {...bindLastName} />
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </CenterContainer>
    );
  }

  const CenterContainer = styled.div`
    display: flex;
    height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
