import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@date-io/date-fns';
import { styled } from '@mui/system';

const formatRentalHours = (startDate, endDate) => {
  if (!startDate || !endDate) return '';

  const milliseconds = endDate - startDate;
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));

  return `${days ? `${days} day${days !== 1 ? 's' : ''}, ` : ''}${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''}`;
};

const DetailLabel = styled(Typography)({
  fontWeight: 'bold',
});

const DetailValue = styled(Typography)({
  fontWeight: 'normal',
});

function RFPForm() {
  const [contactMethod, setContactMethod] = useState('');
  const [value, setValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleContactMethodChange = (event) => {
    setContactMethod(event.target.value);
  };
  const totalPrice = 1000; // Replace this with the actual total price
  const estimatedReach = 5000; //

  const handleSubmit = (event) => {
    event.preventDefault();
    // Implement your logic to send the user's response to the advertiser
  };

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={4}>
        <Typography variant="h5" gutterBottom>
          Fill in the details below to generate your RFP Proposal
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField label="Name" fullWidth required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Email" fullWidth required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Phone number (optional)" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Contact preference</InputLabel>
              <Select value={contactMethod} onChange={handleContactMethodChange} required>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start date and time"
                inputFormat="MM/dd/yyyy hh:mm a"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End date and time"
                inputFormat="MM/dd/yyyy hh:mm a"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Additional comments"
              multiline
              rows={4}
              fullWidth
              placeholder="Write a brief description or any additional comments regarding your response to the ad"
            />
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <DetailLabel variant="body1">Request Details</DetailLabel>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <DetailLabel variant="body1">Total Price:</DetailLabel>
              <DetailValue variant="body1">${totalPrice}</DetailValue>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <DetailLabel variant="body1">Estimated Reach:</DetailLabel>
              <DetailValue variant="body1">{estimatedReach} people</DetailValue>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <DetailLabel variant="body1">Total Rental Hours:</DetailLabel>
              <DetailValue variant="body1">{formatRentalHours(startDate, endDate)}</DetailValue>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" color="secondary" style={{ marginLeft: 16 }}>
              Request Change
            </Button>
            <Button variant="outlined" color="secondary" style={{ marginLeft: 16 }}>
              Save as Draft
            </Button>
            <Button variant="outlined" color="secondary" style={{ marginLeft: 16 }}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default RFPForm;


