// Import necessary libraries
import React from 'react';
import styled from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Styled components
const Card = styled.div`
  position: relative; // Add this line
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: calc(25% - 20px);
  height:220px;
  box-sizing: border-box;
  background-color: white;

  @media (max-width: 480px) {
    width: 80%;
  }
`;


const Logo = styled.img`
  height: 20%;
  width: 20%;
`;

const Title = styled.h3`
  margin: 40px 0;
`;

const Text = styled.p`
  margin-top: -30px;
  color: #666;
`;

const PreviewButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #666;
  position: absolute; // Position absolutely within the card
  top: 10px; // 10px from the top
  right: 10px; // 10px from the right
  font-size: 0.8em; // Smaller text size

  &:hover {
    color: red;
  }

  svg { // Style for the icon
    margin-right: 5px;
  }
`;


// FrontPageCard Component
const FrontPageCard = ({ Icon, title, text, onSeePreviewClick }) => {
  console.log(Icon);
  return (
    <Card>
      <PreviewButton onClick={onSeePreviewClick}>
        <VisibilityIcon />
        SEE PREVIEW
      </PreviewButton>
      {Icon && <Icon style={{ height: '30px', width: '30px', fontSize: 'large' }} />}
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Card>
  );
};

export default FrontPageCard;