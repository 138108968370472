import React from 'react';
import { Chip } from '@mui/material';
import styled from 'styled-components';

const StatusChip = styled(Chip)`
  &.ACTIVE {
    background-color: #8cff8c; /* Light green */
  }
  &.DISABLED {
    background-color: #ff8c8c; /* Light red */
  }
  &.ARCHIVED {
    background-color: #ffff8c; /* Light yellow */
  }
`;


const AdStatusChip = ({ status }) => {
  let label;

  switch(status) {
    case 'ACTIVE':
      label = 'Active';
      break;
    case 'DISABLED':
      label = 'Disabled';
      break;
    case 'ARCHIVED':
      label = 'Archived';
      break;
    default:
      label = 'Unknown';
  }

  return <StatusChip className={status} label={label} />;
};

export default AdStatusChip;