import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'typeface-manrope';

import { createBrowserHistory } from 'history';
import TopNavigation from './components/TopNavigation';
import MapScreen from './screens/MapScreen';
import Footer from './components/Footer';
import NameForm from './screens/AdSpaceEntryForm';
import Listings from './screens/Listings';
import AboutUs from './screens/AboutUs';
import FrontPage from './screens/FrontPage';
import MyAds from './screens/MyAds';
import MyOrders from './screens/MyOrders';
import MyMessages from './screens/MyMessages';
import MyProfile from './screens/MyProfile';
import ListingDetails from './screens/ListingDetails';
import PostAd from './screens/PostAd';
import OrderDetails from './components/OrderDetails'
import MyAdScreen from './screens/myAdScreen';
import CampaignsScreen from './screens/campaignsScreen';
import CampaignDetails from './components/CampaignDetails';
import AnalyticsScreen from './screens/AnalyticsScreen';
import styled from 'styled-components';

const FooterContainer = styled.div`
  padding-top: 50px; // adjust this value to your needs
`;

const App = () => {
  const history = createBrowserHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
    <BrowserRouter history={history} location={history.location}>
    <TopNavigation history={history} searchTerm={searchTerm} setSearchTerm={setSearchTerm} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
          <Route path="/browse-spaces" element={<MapScreen/>}/>
          <Route path="/listings" element={<Listings searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>}/>
          <Route path="/rent-your-space" element={<NameForm/>}/>
          <Route path="/about" element={<AboutUs/>}/>
          <Route path="/" element={<FrontPage/>}/>
          <Route path="/manage/ads" element={<MyAds/>}/>
          <Route path="/manage/orders" element={<MyOrders/>}/>
          <Route path="/manage/messages" element={<MyMessages/>}/>
          <Route path="/manage/profile" element={<MyProfile/>}/>
          <Route path="/listings/:category" element={<Listings searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}/>
          <Route path="/listings/details/:id" element={<ListingDetails />} />
          <Route path="/post" element={<PostAd />} />
          <Route path="/order/:id" element={<OrderDetails/>} />
          <Route path="/myads/:id" element={<MyAdScreen/>} />
          <Route path="/campaigns" element={<CampaignsScreen/>} />
          <Route path="/campaigns/:campaignId" element={<CampaignDetails/>} />
          <Route path="/analytics" element={<AnalyticsScreen/>} />
        </Routes>
        <FooterContainer>
          <Footer />
        </FooterContainer>
  </BrowserRouter>
    <></>
    </>
  );
};

export default App;