import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Divider,
  Grid,
  Button,
  Avatar,
} from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const ProfileSection = styled(Paper)`
  width: 100%;
  max-width: 800px;
  padding: 2rem;
`;

const ProfileImage = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
`;

const sampleProfile = {
  firstName: 'John',
  lastName: 'Doe',
  email: 'john.doe@example.com',
  phone: '555-123-4567',
  company: 'Acme Inc.',
  address: '123 Main St',
  city: 'Springfield',
  state: 'CA',
  zipCode: '90210',
  country: 'USA',
};

function MyProfile() {
  const [country, setCountry] = useState(sampleProfile.country);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <Container>
      <ProfileSection elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <ProfileImage />
          <Typography variant="h5">
            {sampleProfile.firstName} {sampleProfile.lastName}
          </Typography>
        </Box>
        <Divider />
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                defaultValue={sampleProfile.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                defaultValue={sampleProfile.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                defaultValue={sampleProfile.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                defaultValue={sampleProfile.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company"
                variant="outlined"
                defaultValue={sampleProfile.company}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                variant="outlined"
                defaultValue={sampleProfile.address}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                defaultValue={sampleProfile.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{country === 'USA' ? 'State' : 'Province'}</InputLabel>
                <Select
                  label={country === 'USA' ? 'State' : 'Province'}
                  defaultValue={sampleProfile.state}
                >
                  {/* Add your state/province options here */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={country === 'USA' ? 'Zip Code' : 'Postal Code'}
                variant="outlined"
                defaultValue={sampleProfile.zipCode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Country</InputLabel>
                <Select
                  label="Country"
                  value={country}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="USA">USA</MenuItem>
                  <MenuItem value="Canada">Canada</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary">
            Save Changes
          </Button>
        </Box>
      </ProfileSection>
    </Container>
  );
}

export default MyProfile;

