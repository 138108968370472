import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getChatByOrder, addChatMessage } from './api';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
`;

const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const Message = styled.p`
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: ${props => props.isMine ? '#e6f7ff' : '#fff'};
`;

const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  margin-left: 10px;
  padding: 10px;
  border: none;
  background: #1890ff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background: #ccc;
  }
`;

const ChatComponent = ({ orderId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    getChatByOrder(orderId)
      .then(setMessages)
  }, [orderId]);

  const handleNewMessageChange = event => {
    setNewMessage(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    setIsSending(true);
    addChatMessage(orderId, newMessage)
      .then(message => {
        setMessages([...messages, message]);
        setNewMessage('');
      })
      .catch(() => alert('Failed to send message'))
      .finally(() => setIsSending(false));
  };

  console.log(messages);

  return (
    <ChatContainer>
      <MessagesContainer>
        {messages.map((message, i) => (
          <Message key={i} isMine={message.userId === 'myUserId'}>
            {message.email + ": " + message.message}
          </Message>
        ))}
      </MessagesContainer>
      <InputContainer>
        <Input
          value={newMessage}
          onChange={handleNewMessageChange}
          onKeyDown={handleKeyDown}
          placeholder="Type a message"
        />
        <Button
          onClick={handleSendMessage}
          disabled={!newMessage || isSending}
        >
          Send
        </Button>
      </InputContainer>
    </ChatContainer>
  );  
};

export default ChatComponent;