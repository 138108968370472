import React from "react";
import styled from "styled-components";

const ListingsSectionStyled = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ListingsSection = ({ children }) => (
  <ListingsSectionStyled>{children}</ListingsSectionStyled>
);

export default ListingsSection;
