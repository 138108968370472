import React from "react"
import { useState, useEffect } from "react";
import { Map, Marker } from "pigeon-maps"

export function MyMap() {
    const [adRentalSpaces, setAdRentalSpaces] = useState([]);
  const [adRentalSpacesLoaded, setAdRentalSpacesLoaded] = useState(false);


  const markers1 = {
    fredericton: [[45.93002,-66.63019], 13]
  }
  
  const fetchUser = async () => {
    try {
      let response = await fetch('http://localhost:8080/adRentalSpace/all');
      let json = await response.json();
      console.log(json)
      return { success: true, data: json };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
  useEffect(() => {
    (async () => {
      setAdRentalSpacesLoaded(false);
      let res = await fetchUser();
      console.log(res)
      console.log(res.success)
      if (res.success && res.data[0]) {
        console.log(res.data);
        let markers = {};
        res.data.forEach((adRentalSpace , i)=> {
            markers[adRentalSpace.id] = [[parseFloat(adRentalSpace.latitude), parseFloat(adRentalSpace.longitude)],i ]
        }) 
        console.log(markers);
        console.log(markers1);
        setAdRentalSpaces(markers);

        setAdRentalSpacesLoaded(true);
      }
    })();
  }, []);
//  <Marker width={50} anchor={[45.93002,-66.63019]} />

//   const latitude = parseFloat(adRentalSpaces[0].latitude);
//     const longitude = parseFloat(adRentalSpaces[0].longitude);
  return (adRentalSpacesLoaded &&
    <Map height={window.screen.height-400} width={window.screen.width} defaultCenter={[45.93002,-66.63019]} defaultZoom={11}>
        {Object.keys(adRentalSpaces).map((key, index) => (
            <Marker
              key={key}
              anchor={adRentalSpaces[key][0]}
              width={50}
            />  
          ))})
    </Map>
  )

  
}