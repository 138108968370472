import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Typography, TextField, Box, ToggleButtonGroup, ToggleButton, Button} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import PaginationRounded from '../components/pagination';
import ListingItem from '../components/ListingItem';
import { createDummyData } from '../components/helperFunctions';
import { listAllByUser } from '../components/api';
import Select from 'react-select'
import { TitleContainer, StyledTitle } from './MyOrders';
const Container = styled.div`
  padding: 2rem;

  @media (max-width: 768px) {

    display: flex; // Add this line
    flex-direction: column; // Ensure vertical alignment
    align-items: center; // This will now work  }
  }
`;
const Title = styled(Typography)`
  margin-bottom: 1rem;
`;

const SearchBar = styled(TextField)`
  width: 300px;
`;

export const MyAdsListingsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const NoOrders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  color: #333;
`;


const MyAds = () => {
  const [activeStatus, setActiveStatus] = useState('All');
  const [sampleRows, setSampleRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setActiveStatus(newStatus);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sampleAds = await listAllByUser();
        setSampleRows(sampleAds);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };
    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpen =() => {
    navigate("/post");
  }

  console.log(sampleRows);
  const filteredAds = sampleRows
    .filter((ad) => activeStatus === 'All' || ad.status.toLowerCase() === activeStatus)
    .filter((ad) => ad.title.toLowerCase().includes(searchQuery.toLowerCase()) || ad.description.toLowerCase().includes(searchQuery.toLowerCase()));



  if (sampleRows.length === 0 ) {
    return (

      <NoOrders>

      <Typography variant="h4" component="h2" gutterBottom>
        No ads yet
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Any ads you post to the site will show up and be managed from this tab
      </Typography>
      <Button variant="contained"  onClick={handleOpen} style={{ backgroundColor: "#ED2647", marginTop: '2rem' }}>
        Create an ad listing
      </Button>
        </NoOrders>

        )
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      width: 150,
      marginTop:15,
    })
  };

  const options = [
    { value: 'All', label: 'All' },
    { value: 'ACTIVE', label: 'Active' },
    { value: 'REQUESTED', label: 'Requested' },
    { value: 'DRAFTS', label: 'Drafts' },
    { value: 'ARCHIVED', label: 'Archived' },
  ];

  return (
    <Container>
      <Button variant="contained"  onClick={handleOpen} style={{ backgroundColor: "#ED2647", marginTop: '-1rem', marginBottom: '2rem' }}>
        Create listing
      </Button>
      <TitleContainer>
        <StyledTitle>
          Status:&nbsp;
        </StyledTitle>
        <Select styles={customStyles} className="basic-single" defaultValue={options[0]} options={options} onChange={handleStatusChange}/>
      </TitleContainer>
      <MyAdsListingsSection>
        {filteredAds.map((ad) => (
          <ListingItem
            key={ad.id}
            listing={ad}
            pricePeriod="week"
            reachPeriod="hour"
            myAds={true}
          />
        ))}
      </MyAdsListingsSection>

      <PaginationRounded />
    </Container>
  );
};

export default MyAds;