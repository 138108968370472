import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CheckCircle, Error, Help, Close, ExpandMore, Delete } from '@mui/icons-material';
import styled from 'styled-components';
import { getOrdersByCampaign, updateCampaign, deleteCampaign } from '../components/api.js';
import { useNavigate } from 'react-router-dom';

const Title = styled(Typography)`
  font-family: 'Manrope', sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

const OrderStatus = ({ status }) => {
  if (status === 'accepted') {
    return (
      <>
        <CheckCircle color="success" />
        <Typography variant="subtitle2" ml={1}>
          Accepted
        </Typography>
      </>
    );
  } else if (status === 'needsWork') {
    return (
      <>
        <Error color="warning" />
        <Typography variant="subtitle2" ml={1}>
          Needs Work
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Help color="disabled" />
        <Typography variant="subtitle2" ml={1}>
          Not Submitted
        </Typography>
      </>
    );
  }
};

const CampaignDetails = () => {
  const { campaignId } = useParams();
  console.log(campaignId);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const ordersData = await getOrdersByCampaign(campaignId);
      console.log(ordersData);
      setOrders(ordersData);
    };

    fetchOrders();
  }, [campaignId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRemoveOrder = async (orderId) => {
    const updatedCampaign = { ...orders.find((order) => order.id === campaignId) };
    updatedCampaign.orderIds = updatedCampaign.orderIds.filter((id) => id !== orderId);
    await updateCampaign(campaignId, updatedCampaign);
    setOrders(orders.filter((order) => order.id !== orderId));
  };

  const handleDeleteCampaign = async () => {
    await deleteCampaign(campaignId);
    navigate('/campaigns'); // Redirect to home page or any desired page after deletion
  };
  console.log(orders);
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title variant="h4">Campaign Details</Title>
          {orders.map((order) => (
            <Accordion key={order.id}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${order.id}-content`}
                id={`panel${order.id}-header`}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography>{order.listing.title}</Typography>
                  <OrderStatus status={order.status} />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Listing Title: {order.listing.title}</Typography>
                    <Typography variant="subtitle1">Description: {order.listing.description}</Typography>
                    <Typography variant="subtitle1">Reach per Hour: {order.listing.reachPerHour}</Typography>
                    <Typography variant="subtitle1">Price per Hour: {order.listing.pricePerHour}</Typography>
                    <Typography variant="subtitle1">Total Reach: {order.totalReach}</Typography>
                    <Typography variant="subtitle1">Total Price: {order.totalPrice}</Typography>
                    <Typography variant="subtitle1">
                      Selected Dates: {order.selectedDates?.join(', ')}
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={() => handleRemoveOrder(order.id)}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Box mt={4} mb={4}>
            <Button variant="contained" color="secondary" onClick={handleClickOpen}>
              Delete Campaign
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Delete Campaign?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this campaign? This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteCampaign} color="secondary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );  
};
export default CampaignDetails;
               
