import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 240px;
  width: 240px;
  text-align: center;
  margin-right: 7px;
  margin-left: 7px;

  margin-bottom: 8px; // Add extra space below each InfoItem
`;

const StyledIcon = styled.div`
  margin-bottom: 10px; // Space between icon and title
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Title = styled.h3`
  margin-bottom: 5px; // Space between title and text
  width: 100%;
`;

const Text = styled.p`
  width: 100%;
`;

// InfoItem Component
const InfoItem = ({ Icon, title, text }) => {
  return (
    <Container>
      <StyledIcon>
        {Icon && <Icon style={{ height: '30px', width: '30px', fontSize: 'large' }} />}
      </StyledIcon>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
};

export default InfoItem;
