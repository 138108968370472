import React, { useState } from 'react';
import styled from 'styled-components';
import FormPopup from '../components/FormPopup';
import AuthModal from "../components/AuthModal";
import FrontPageCard from '../components/FrontPageCard';

import AirplayIcon from '@mui/icons-material/Airplay';
import EmailIcon from '@mui/icons-material/Email';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import BallotIcon from '@mui/icons-material/Ballot';

import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import MapIcon from '@mui/icons-material/Map';
import GroupsIcon from '@mui/icons-material/Groups';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import InfoItem from "../components/InfoItem";

const Body = styled.div`
  background: #fef4ea;
  height: auto; // Changed to auto for flexible height
  display: flex;
  flex-direction: row; // Default to row
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column; // Stack vertically on small screens
  }
`;

const Body2 = styled.div`
  height: auto; // Changed to auto for flexible height
  display: flex;
  flex-direction: row; // Default to row
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column; // Stack vertically on small screens
  }
`;


const LeftContainer = styled.div`
  flex: 1;
  padding: 20px;
  padding-left: 70px;
  display: flex;
  width:100px;
  max-width: 500px;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center; // Center align all items
    text-align: center; // Center align all text
    padding-left: 5px; // Remove extra padding on the left
    padding-right: 15px; // Remove extra padding on the right
    width: 100%; // Ensure it takes the full width
  }
`;

const RightContainer2 = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  width:100px;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  align-items: center; // Center align all items
  text-align: center; // Center align all text
  

  @media (max-width: 768px) {
    
    padding-left: 5px; // Remove extra padding on the left
    padding-right: 15px; // Remove extra padding on the right
    width: 100%; // Ensure it takes the full width
  }
`;

const RightContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 13px;
  font-family: Arial, sans-serif; // Set Arial font here
  color: #213343;
`;


const Subtitle = styled.p`
  margin-top: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #213343;
  font-family: Arial, sans-serif; // Set Arial font here
  @media {
    width: 80%;
  }
`;

const Subtitle2 = styled.p`
  margin-top: 25px;
  font-size: 15px;
  font-weight: bold;
 
  color: #213343;
  font-family: Arial, sans-serif; // Set Arial font here
  width: auto;

  @media (max-width: 100px) {
    font-size: 14px; // Adjust font size for smaller screens
    width: 100%; // Ensure it takes the full width of its container
    min-width: initial; // Remove any minimum width constraints
    word-wrap: break-word; // Break words to prevent horizontal scrolling
    padding: 0 10px; // Add padding for better readability
  }
`;

const Subtitle4 = styled.p`
  margin-top: -10px;
  color: #666;
  text-align: center;
  padding-left: 20px; // Add padding to the left
  padding-right: 20px; // Add padding to the right

  @media (max-width: 768px) { // Adjust for smaller screens like mobile devices
    padding-left: 25px;
    padding-right: 25px;
  }
`;


const Subtitle3 = styled.p`
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;

  color: #213343;
  font-family: Arial, sans-serif; // Set Arial font here

  @media (max-width: 768px) {
    font-size: 14px; // Adjust font size for smaller screens
    width: 80%; // Ensure it takes the full width of its container
    min-width: initial; // Remove any minimum width constraints
    word-wrap: break-word; // Break words to prevent horizontal scrolling
    padding: 0 10px; // Add padding for better readability
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;

const ButtonContainer2 = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  alight-items:center;
`;

export const Button1 = styled.a`
  padding: 10px 20px;
  border: 2px solid #ED2647; // Orange border
  background-color: #fff;  // White background
  color: #ED2647; // Orange text
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  justify-self: center;
  border-radius: 12px;
  width: 200px;
  text-align: center; // Center text horizontally
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: auto; // Adjust width for mobile
    padding: 10px; // Adjust padding if necessary
  }
`;

export const Button2 = styled.a`
  padding: 10px 20px;
  border: none;
  min-width: 120px;
  width: 200px;
  background-color: #ED2647;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  justify-self: center;
  border-radius: 12px;
  text-align: center; // Center text horizontally
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: auto; // Adjust width for mobile
    padding: 10px; // Adjust padding if necessary
  }
`;


const SvgContainer = styled.div`
  img {
    max-width: 100%; // Make image responsive
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 768px) {
    width: 100%; // Adjust container width for mobile
    display: flex;
    justify-content: center; // Center the image
  }`;

// React Component
const FrontPage = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [modalType, setModalType] = useState('signup');
  const [openSignUp, setOpenSignUp] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [toastSeverity, setToastSeverity] = useState('success');

  const infoItemData = [
    { Icon: LocationSearchingIcon, title: 'Discovery', text: 'Use our extensive search tools to find the perfect ad space for you.' },
    { Icon: MapIcon, title: 'Planning', text: 'Create a campaign to manage many ad space deals at once.' },
    { Icon: GroupsIcon, title: 'Aggregation', text: 'Group all repeated requirements from ad spaces in a campaign so you only fulfill requirements once.' },
    { Icon: RocketLaunchIcon, title: 'Launch', text: 'Generate and submit RFPs for all ad space rentals in a campaign at once, with extensive revision tools if a deal needs to be changed.'},
  ];

  const cardData = [
    { Icon: AirplayIcon, title: 'Physical Advertisements', text: 'Support for physical advertisement deals such as billboards, posters, flyers' },
    { Icon: EmailIcon, title: 'Email', text: 'Add your product to existing email campaigns' },
    { Icon: LiveTvIcon, title: 'Sponsorships', text: 'Find sponsorship deals from influencers, podcasts, sports teams etc.' },
    { Icon: BallotIcon, title: 'Native advertising', text: 'Make your product blend in to reach your target audience' },
  ];

  const handleSeePreviewClick = () => {
    console.log('Preview clicked');
  };
  const handleToastClose = () => {
    setToastOpen(false);
  };

  const handleLoginToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  const handleButtonClick = () => {
      setOpenSignUp(true);
      setModalType("signup");

  };
  const handleDemoButtonClick = () => {
    setPopupVisible(true);
  };
  return (
    <>
    <Body>
      <LeftContainer>
        <Title>FOR ADVERTISERS</Title>
        <Subtitle>Launch self-service ad campaigns that genuinely engage with your target audience.</Subtitle>
        <Subtitle3>Simple to use software for marketers to sell advertisement spaces directly to advertisers.</Subtitle3>
        <ButtonContainer>
          <Button2 onClick={handleDemoButtonClick}>Get a demo</Button2>
          <Button1 href="#" onClick={() =>handleButtonClick()}>Get started free</Button1>
        </ButtonContainer>
        <FormPopup isVisible={isPopupVisible} onClose={() => setPopupVisible(false)} />
        <Subtitle2>Get a demo of our premium software, or get started with free tools.</Subtitle2>
      </LeftContainer>
      <RightContainer>
        <SvgContainer>
          <img src="https://uploads-ssl.webflow.com/65693a464f251f6c536b8fe5/656948303c26acb955c54a50_heroImg2.svg" loading="lazy" width="489" data-wf-id="[&quot;a36a222b-d7f4-9244-1ee6-0fd7490f279f&quot;]" alt="" data-w-id="a36a222b-d7f4-9244-1ee6-0fd7490f279f"/>
        </SvgContainer>
      </RightContainer>
      <AuthModal
        action={modalType}
        onLoginToast={() => handleLoginToast(toastMessage, toastSeverity)}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        openSignUp={openSignUp}
        setOpenSignUp={setOpenSignUp}
        hideButton={true}
      />
    </Body>
    <Body>
      {cardData.map((card, index) => (
      <FrontPageCard
        key={index}
        Icon={card.Icon}
        title={card.title}
        text={card.text}
        onSeePreviewClick={handleSeePreviewClick}
      />
      ))}
    </Body>
      <Body>
        <RightContainer2>
          <Subtitle>
            What we do
          </Subtitle>
          <Subtitle4>
            AdMaps is an online marketplace for advertisers and marketers, with many tools built in to make the process simple. We believe that modern advertisers want an environment where they can choose the exact advertisement spaces they rent from multiple providers without having to deal with a large firm, AdMaps exists to serve this role. If you want to rent ad spaces from different mediums and different providers and get the entire deal done on one platform, AdMaps can accomplish this in a self-service manner where you are in control.
          </Subtitle4>
        </RightContainer2>
      </Body>
      <Body>
        {infoItemData.map((item, index) => (
          <InfoItem
            key={index}
            Icon={item.Icon}
            title={item.title}
            text={item.text}
          />
        ))}

      </Body>
      <Body2>
        <ButtonContainer>
          <Button2 onClick={handleDemoButtonClick}>Get a demo</Button2>
          <Button1 href="#" onClick={() =>handleButtonClick()}>Get started free</Button1>
        </ButtonContainer>
      </Body2>

  </>

  );
};

export default FrontPage;