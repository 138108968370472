import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { loginUser, logoutUser, registerUser } from './api';
import { MdAccountCircle } from 'react-icons/md';
import Toast from './Toast';
import { Button1 } from '../screens/FrontPage'
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading spinner

export const ModalBackground = styled.div`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalCard = styled.div`
  position: relative; // Make ModalCard a relative container
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 40%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  margin-bottom: 20px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Dropdown = styled.div`
  position: absolute;
  display: ${(props) => (props.open ? 'block' : 'none')};
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
  top: calc(100% + 5px); // Add this line
  left: 0; // Add this line

  & > p {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  & > p:hover {
    background-color: #f1f1f1;
  }
`;


export const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
`;



const Icon = styled(MdAccountCircle)`
  margin-right: 5px;
  font-size: 24px;
`;

const AuthModal = ({ action, isLoggedIn, setIsLoggedIn, openSignUp, setOpenSignUp, setModalType, hideButton }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [earlyAccessCode, setEarlyAccessCode] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest('.profile-container')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      setEmail(localStorage.getItem('email'));
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleRegister = async (email, password) => {
    try {
      setIsLoading(true);
      await registerUser(email, password);
      setIsLoading(false);
      handleLogin(email, password);
      if (!(toastOpen && toastMessage === 'Successfully registered')) {
        setToastMessage('Successfully registered');
        setToastSeverity('success');
        setToastOpen(true);
      }
    } catch (error) {
      // todo Display an error message to the user
    }
  };
  
  const handleLogin = async (email, password) => {
    try {
      const token = await loginUser(email, password);
      localStorage.setItem('email', email);
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
      handleClose();
      if (!(toastOpen && toastMessage === 'Successfully logged in')) {
        setToastMessage('Successfully logged in');
        setToastSeverity('success');
        setToastOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 403 && !(toastOpen && toastMessage === 'User credentials are incorrect')) {
        setToastMessage('User credentials are incorrect');
        setToastSeverity('error');
        setToastOpen(true);
      }
      // todo Display other error messages to the user
    }
  };
  
  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      setShowDropdown(false);
      if (!(toastOpen && toastMessage === 'Successfully logged out')) {
        setToastMessage('Successfully logged out');
        setToastSeverity('success');
        setToastOpen(true);
      }
      removeToken();
    } catch (error) {
      removeToken();
    }
  };
  
  
  

  const handleOpen = () => {
    setModalType("login");
    setOpen(true);
    setOpenSignUp(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Reset the openSignUp state
    setOpenSignUp(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (isRegister) {
      if (earlyAccessCode === "AdMapsEarly1") {
        handleRegister(email, password);
      } else {
          setToastMessage('Failed: Access code required');
          setToastSeverity('error');
          setToastOpen(true);
      }
    } else {
      handleLogin(email, password);
    }
  };

  const removeToken = () => localStorage.removeItem('token');

  const isTokenExpired = () => {
    const token = localStorage.getItem('token');
    if (!token) return true;
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Date.now() / 1000;
      return exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const checkTokenExpiration = setInterval(() => {
        if (isTokenExpired()) {
          handleLogout();
          setIsLoggedIn(false);
        }
      }, 60000); // Check every minute
  
      return () => {
        clearInterval(checkTokenExpiration);
      };
    }
  }, [isLoggedIn]);
  
  

  const handleProfileSettings = () => {
    setShowDropdown(false);
    navigate('/manage/profile');
  };

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const isRegister = action === 'signup';
  return (
    <>
      {
        !hideButton && (
          isLoggedIn ? (
            <ProfileContainer className="profile-container" onClick={handleDropdown}>
              <p>{email}</p>
              <Icon />
              <Dropdown open={showDropdown}>
                <p onClick={handleProfileSettings}>Profile Settings</p>
                <p onClick={handleLogout}>Log out</p>
              </Dropdown>
            </ProfileContainer>
          ) : (
            <ProfileContainer className="profile-container" onClick={handleOpen}>
              <p>Log in</p> &nbsp;
              <Icon />
            </ProfileContainer>
          )
        )
      }
      <ModalBackground open={open || openSignUp} onClick={handleClose}>
      <ModalCard onClick={(e) => e.stopPropagation()}>
        <Title>{isRegister ? 'Sign up' : 'Log in'}</Title>
        <Form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          {
            isRegister && (
              <>
                <Input
                  type="text"
                  placeholder="Early access code"
                  value={earlyAccessCode}
                  onChange={(event) => setEarlyAccessCode(event.target.value)}
                  required
                />
                <p>
                  Sign up is restricted to early access code owners only, book a demo to get yours!
                </p>
              </>
            )
          }
          <Button1 type="submit" onClick={(event) => handleSubmit(event)}>
            {isLoading ? <CircularProgress size={24} style={{ color: '#ED2647' }}
            /> : (isRegister ? 'Sign up' : 'Log in')}
          </Button1>
        </Form>
        </ModalCard>
      </ModalBackground>
      <Toast
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export default AuthModal;

