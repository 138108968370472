import React from 'react';
import styled from 'styled-components';
function AboutUs() {
  return (
    <CenterContainer>
        <div className="login-wrapper">

            <h1>AdMaps</h1>

            <form>
                <label>
                    <p>AdMaps exists to make buying and selling advertisement opporotunities easy</p>
                </label>
                <h1>Buyers:</h1>
                <label>
                    <p>     1. Find advertisement opportunity for rent  </p> 
                    <p>     2. Choose how long you want your advertisement up</p>
                    <p>     3. Finalize deal</p>
                </label>
                <h1>Sellers</h1>
                <label>
                    <p>1. List your advertisement opportunity for rent</p>
                    <p>2. Approve and display advertising</p>
                    <p>3. Finalize deal </p>
                </label>
        
                <label>
                    <p>Connect with the development and customer service: help@admaps.ca</p>
                </label>
            </form>
    </div>
  </CenterContainer>
  );
}

const CenterContainer = styled.div`
    display: flex;
    height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export default AboutUs;
