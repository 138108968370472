import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthModal from './AuthModal'
import Toast from './Toast';
import colors from '../colors';

const NavBar = styled.nav`
  background-color: #ffffff;
  padding: 1rem 2rem;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: nowrap; // Default to not wrapping

  @media (max-width: 768px) { // Adjust for mobile screens
    flex-wrap: wrap; // Allow items to wrap on smaller screens
  }
`;

const Logo = styled.div`
  background-image: url('https://i.imgur.com/AHx8DWs.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 150px; // You can adjust the width as needed
  min-width: 150px;
  min-height: 60px;
  height: 60px; // You can adjust the height as needed
  display: inline-block;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LocationSelect = styled.select`
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const SearchBar = styled.input.attrs(props => ({
}))`
  padding: 0.5rem;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  @media {
    width: 200px;
  }
`;

const Button = styled.button`
  background-color: ${colors.primary.hex};
  color: ${colors.background.hex};
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
  transition: background-color 0.3s;
  min-width:110px;
  max-width:110px;
  max-height:40px;

  &:hover {
    background-color: ${colors.secondary.hex};
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) { // Example breakpoint for mobile devices
    flex-wrap: wrap;
    justify-content: center; // Optional: Adjust alignment after wrapping
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledNavLink = styled(NavLink)`
  color: #333;
  text-decoration: none;
  margin: 0 1rem;
  position: relative;
  transition: color 0.3s;

  &:hover {
    color: #0366d6;
  }

  &.active {
    color: #0366d6;
  }
`;

const CategoryLinks = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) { // A common breakpoint for mobile devices
    gap: -90px; // Reduce the gap between items
  }
`;

const CategoryLink = styled(StyledNavLink)`
  font-size: 1rem;
  &.active {
    color: #0366d6;
  }
  @media (max-width: 768px) { // Adjust the font size on mobile if needed
    margin-right: -4px;

  }
`;

const TopNavigation = ({ searchTerm, setSearchTerm, isLoggedIn, setIsLoggedIn }) => {
  const [isManage, setIsManage] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);

  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');
  const [modalType, setModalType] = useState('login');

  const handleToastClose = () => {
    setToastOpen(false);
  };

  const handleLoginToast = (message, severity) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

    // Add useLocation hook
    const location = useLocation();

    const handleSearch = () => {
      setSearchTerm(inputValue);
      if (!location.pathname.includes('/listings')) {
        navigate('/listings');
      }
    };

  const toggleCategories = (selectedMenu) => {
    setIsManage(selectedMenu === "manage");
  };

  const handleButtonClick = (option) => {
    if (isLoggedIn) {
      navigate("/post");
    } else {
      setOpenSignUp(true);
      if (option === "Sign up") {
        setModalType("signup");
      } else {
        setModalType("login");
      }
    }
  };

  const manageCategories = (
    <CategoryLinks>
      <CategoryLink to="/manage/ads" activeClassName="active">
        Listings
      </CategoryLink>
      <CategoryLink to="/manage/orders" activeClassName="active">
        Orders
      </CategoryLink>
      <CategoryLink to="/campaigns" activeClassName="active">
        Campaigns
      </CategoryLink>
      <CategoryLink to="/manage/messages" activeClassName="active">
        Messages
      </CategoryLink>
      <CategoryLink to="/analytics" activeClassName="active">
        Analytics
      </CategoryLink>
    </CategoryLinks>
  );

  // The adCategories with the new isActive prop
  const adCategories = (
    <CategoryLinks>
      <CategoryLink to={`/listings/billboards`} activeClassName="active">
        Billboards
      </CategoryLink>
      <CategoryLink to={`/listings/transit`} activeClassName="active">
        Transit
      </CategoryLink>
      <CategoryLink to={`/listings/radio`} activeClassName="active">
        Radio
      </CategoryLink>
      <CategoryLink to={`/listings/social_media`} activeClassName="active">
        Social Media
      </CategoryLink>
      <CategoryLink to={`/listings/email_marketing`} activeClassName="active">
        Email Marketing
      </CategoryLink>
      <CategoryLink to={`/listings/direct_mail`} activeClassName="active">
        Direct Mail
      </CategoryLink>
      <CategoryLink to={`/listings/guerrilla_marketing`} activeClassName="active">
        Guerrilla Marketing
      </CategoryLink>
      <CategoryLink to={`/listings/ambient`} activeClassName="active">
        Ambient
      </CategoryLink>
      <CategoryLink to={`/listings/sponsorships`} activeClassName="active">
        Sponsorships
      </CategoryLink>
      <CategoryLink to={`/listings/events`} activeClassName="active">
        Events
      </CategoryLink>
      <CategoryLink to={`/listings/product_placement`} activeClassName="active">
        Product Placement
      </CategoryLink>
      <CategoryLink to={`/listings/influencer_marketing`} activeClassName="active">
        Influencer Marketing
      </CategoryLink>
    </CategoryLinks>
  );

  return (
    <NavBar>
      <TopRow>
        <Logo />
        {isLoggedIn &&
        <SearchContainer>
          <SearchBar
            type="text"
            placeholder="Search for ad spaces..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <Button onClick={handleSearch}>Search</Button>
        </SearchContainer>
  }
        <NavLinks>
          {/*<StyledNavLink exact to="/" activeClassName="active">*/}
          {/*  Home*/}
          {/*</StyledNavLink>*/}
          
          {isLoggedIn && (
            <>
              {/*<StyledNavLink*/}
              {/*  to="/manage/ads"*/}
              {/*  activeClassName="active"*/}
              {/*  onClick={() => toggleCategories("manage")}*/}
              {/*>*/}
              {/*  Manage*/}
              {/*</StyledNavLink>*/}
              <StyledNavLink
          to="/listings"
          activeClassName="active"
          onClick={() => toggleCategories("browse")}
        >
          Browse
        </StyledNavLink>
            </>
          )}
          <AuthModal
            action={modalType}
            onLoginToast={handleLoginToast}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            openSignUp={openSignUp}
            setOpenSignUp={setOpenSignUp}
            setModalType={setModalType}
            hideButton={false}
          />

          {/*<Button onClick={() => handleButtonClick("Sign up")}>*/}
          {/*  {isLoggedIn ? "Post Ad" : "Sign Up"}*/}
          {/*</Button>*/}
  
        </NavLinks>
      </TopRow>
      {isLoggedIn &&
      (<BottomRow>
        {manageCategories}
      </BottomRow>
      )}
      <Toast
          open={toastOpen}
          handleClose={handleToastClose}
          message={toastMessage}
          severity={toastSeverity}
        />
    </NavBar>
  );
};

export default TopNavigation;