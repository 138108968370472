import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

const FooterWrapper = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 3rem 0 2rem 0;
  font-size: 0.9rem;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
`;

const ColumnTitle = styled.h3`
  margin-bottom: 1rem;
`;

const ColumnLink = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  margin: 0.5rem 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ThinLine = styled.hr`
  width: 100%;
  border: 0.5px solid #fff;
  margin: 1rem 0;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  background-image: url('https://i.imgur.com/Zd76kKx.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 40px;
  display: inline-block;
  margin-right: 1rem;
`;

const LinkSeparator = styled.span`
  margin: 0 0.5rem;
`;

const Currency = styled.span`
  margin-right: 1rem;
`;

const SocialMediaIcon = styled.i`
  margin-left: 1rem;
  cursor: pointer;
`;

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <FooterWrapper>
      <FooterContainer>
        <Column>
          <ColumnTitle>Info</ColumnTitle>
          <ColumnLink to="/faq">Q&A</ColumnLink>
          <ColumnLink to="/contact">Contact</ColumnLink>
          <ColumnLink to="/guides">Guides</ColumnLink>
        </Column>
        <Column>
          <ColumnTitle>Navigate</ColumnTitle>
          <ColumnLink exact to="/" onClick={scrollToTop}>Home</ColumnLink>
          <ColumnLink to="/manage/ads" onClick={scrollToTop}>Manage</ColumnLink>
          <ColumnLink to="/listings" onClick={scrollToTop}>Browse</ColumnLink>
        </Column>
      </FooterContainer>
      <ThinLine />
      <BottomSection>
        <LeftSection>
          <Logo />
          <span>AdMaps</span>
          <LinkSeparator>|</LinkSeparator>
          <ColumnLink to="/terms">Terms</ColumnLink>
          <LinkSeparator>|</LinkSeparator>
          <ColumnLink to="/privacy">Privacy</ColumnLink>
        </LeftSection>
        <div>
          <Currency>CAD</Currency>
          <SocialMediaIcon className="fab fa-facebook-f" />
          <SocialMediaIcon className="fab fa-twitter" />
          <SocialMediaIcon className="fab fa-linkedin-in" />
          <SocialMediaIcon className="fab fa-instagram" />
        </div>
      </BottomSection>
    </FooterWrapper>
  );
};

export default Footer;
