import React, {useState} from "react";
import {
  TextField,
} from "@mui/material";

const PriceInput = ({ label, value, onChange }) => {
    const [isFocused, setIsFocused] = useState(false);
  
    const formatPrice = (number) => {
        if (number >= 1_000_000) {
          return `$${(number / 1_000_000).toFixed(0)}M`;
        } else if (number >= 1_000) {
          return `$${(number / 1_000).toFixed(0)}K`;
        } else {
          return `$${number.toFixed(0)}`;
        }
      };      
  
    const handleFocus = () => {
      setIsFocused(true);
    };
  
    const handleBlur = () => {
      setIsFocused(false);
    };
  
    const handleChange = (e) => {
      const newValue = parseInt(e.target.value);
      onChange(isNaN(newValue) ? 0 : newValue);
    };
    const displayValue = isFocused ? value : formatPrice(value);
  
    return (
      <TextField
        label={label}
        type={isFocused ? "number" : "text"}
        variant="outlined"
        size="small"
        InputProps={{ inputProps: { min: 0 } }}
        style={{ width: "45%" }}
        value={displayValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  };

  export default PriceInput;