import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Divider,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, Assignment } from '@mui/icons-material';
import PaginationRounded from '../components/pagination';
import ChatComponent from '../components/ChatComponent';
import { listAllOrdersByUser } from '../components/api';
import { useParams, useNavigate } from 'react-router-dom';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const MessagesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MessageBox = styled(Paper)`
  padding: 1rem;
`;

const ExpandIcon = styled(IconButton)`
  transform: rotate(${(props) => (props.expanded ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const OrderButton = styled(IconButton)`
  margin-right: 10px;
`;

function MyMessages() {
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('All messages');
  const [expandedMessage, setExpandedMessage] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orders = await listAllOrdersByUser();
        setFilteredOrders(orders);
      } catch (error) {
        console.error('Error fetching orders: ', error);
      }
    };
    fetchData();
  }, []);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value === 'All messages') {
      setFilteredOrders(filteredOrders);
    } else {
      setFilteredOrders(
        filteredOrders.filter((message) => message.status === event.target.value)
      );
    }
  };

  const handleAccordionChange = (index) => (_, isExpanded) => {
    setExpandedMessage(isExpanded ? index : null);
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  return (
    <Container>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          My Messages
        </Typography>
        <Divider />
      </Box>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Type</InputLabel>
        <Select
          label="Status"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <MenuItem value="All messages">All messages</MenuItem>
          <MenuItem value="My Ads">My Ads</MenuItem>
          <MenuItem value="Replying to">Replying to</MenuItem>
        </Select>
      </FormControl>

      <MessagesSection>
        {filteredOrders.map((message, index) => (
          <Accordion
            key={message.id}
            expanded={expandedMessage === index}
            onChange={handleAccordionChange(index)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandIcon expanded={expandedMessage === index}>
                  {expandedMessage === index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </ExpandIcon>
              }
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <OrderButton
                    onClick={() => handleOrderClick(message.id)}
                    aria-label="Go to Order"
                  >
                    <Assignment />
                  </OrderButton>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">{message.title}</Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {message.listing.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {message.listing.description}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Status: {message.status}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ChatComponent orderId={message.id} />
            </AccordionDetails>
          </Accordion>
        ))}
        <PaginationRounded />
      </MessagesSection>
    </Container>
  );
}

export default MyMessages;