import React, {useEffect, useState} from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography, useMediaQuery,
} from "@mui/material";
import colors from '../colors';
import Autocomplete from "@mui/material/Autocomplete";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import PriceInput from "./PriceInput";
import ReachInput from "./ReachInput";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CollapseButton = styled.div`
  cursor: pointer;
  width:15px;
  height: 15px;
  position: absolute;
  // right: ${({ isMobile }) => (!isMobile ? 'auto' : '50%')};
  // bottom: ${({ isMobile }) => (!isMobile ? '50%' : 'auto')};
  right: 50%;
  bottom: ${({ isCollapsed }) => (!isCollapsed ? '0%' : '5%')};
  padding-bottom: 10px;
  
  transition: transform 1s ease;
`;

const StyledFilterSection = styled(Paper)`
  width: 25%;
  padding: 1rem;
  margin-right: 2rem;
  position: relative;
  overflow: hidden;
  transition: height 0.3s ease;
  height: ${({ isCollapsed }) => (isCollapsed ? '50px' : '100%')};

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const FilterSection = ({
  categoryFilter,
  setCategoryFilter,
  sortOption,
  setSortOption,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  minReach,
  setMinReach,
  maxReach,
  setMaxReach,
  reachPeriod,
  setReachPeriod,
  pricePeriod,
  setPricePeriod,
  locationFilter,
  setLocationFilter,
  locations
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');
  console.log("isMobile = " + isMobile)
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setCategoryFilter(newCategory);

    if (newCategory) {
      navigate(`/listings/${newCategory}`);
    } else {
      navigate("/listings");
    }
  };
  const handleLocationChange = (event) => {
    console.log(event);
    const newLocation = event.target.outerText || 'All';
    setLocationFilter(newLocation);
  };
  console.log(locationFilter);
    return (
    <StyledFilterSection elevation={3} isCollapsed={isCollapsed}>
      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Filters
        </Typography>
        <Divider />
      </Box>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Sort By</InputLabel>
        <Select
            label="Sort By"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            >
              <MenuItem value="Recent">Recent</MenuItem>
          <MenuItem value="Price - Low">Price - Low</MenuItem>
          <MenuItem value="Price - High">Price - High</MenuItem>
          <MenuItem value="Reach - Low">Reach - Low</MenuItem>
          <MenuItem value="Reach - High">Reach - High</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
            label="Category"
            value={categoryFilter}
            onChange={handleCategoryChange}
        >
            <MenuItem value="All">All</MenuItem>
          <MenuItem value="billboards">Billboards</MenuItem>
          <MenuItem value="transit">Transit</MenuItem>
          <MenuItem value="radio">Radio</MenuItem>
          <MenuItem value="social_media">Social Media</MenuItem>
          <MenuItem value="email_marketing">Email Marketing</MenuItem>
          <MenuItem value="direct_mail">Direct Mail</MenuItem>
          <MenuItem value="guerrilla_marketing">Guerrilla Marketing</MenuItem>
          <MenuItem value="ambient">Ambient</MenuItem>
          <MenuItem value="sponsorships">Sponsorships</MenuItem>
          <MenuItem value="events">Events</MenuItem>
          <MenuItem value="product_placement">Product Placement</MenuItem>
          <MenuItem value="influencer_marketing">Influencer Marketing</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
  <Typography variant="subtitle1">Location</Typography>
  <Autocomplete
    value={locationFilter}
    onChange={(event, newValue) => handleLocationChange(event, newValue)}
    options={locations}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Search or Select Location"
        variant="outlined"
        value={locationFilter}
      />
    )}
    />
    </FormControl>

  
      {/* Other filters */}
      {/* Price Range */}
      <FormControl fullWidth margin="normal">
        <Typography variant="subtitle1">Price Range</Typography>
        <Box display="flex" justifyContent="space-between">
          <PriceInput
            label="Min Price"
            value={minPrice}
            onChange={(newValue) => setMinPrice(newValue)}
          />
          <PriceInput
            label="Max Price"
            value={maxPrice}
            onChange={(newValue) => setMaxPrice(newValue)}
          />
        </Box>
      </FormControl>
      <FormControl fullWidth variant="outlined" size="small" margin="normal">
        <InputLabel>Price Period</InputLabel>
        <Select
        label="Price Period"
        value={pricePeriod}
        onChange={(e) => setPricePeriod(e.target.value)}
        >
        <MenuItem value="hour">Per Hour</MenuItem>
        <MenuItem value="day">Per Day</MenuItem>
        <MenuItem value="week">Per Week</MenuItem>
        <MenuItem value="month">Per Month</MenuItem>
        </Select>
    </FormControl>
      {/* Reach Range */}
      <FormControl fullWidth margin="normal">
            <Typography variant="subtitle1">Reach Range</Typography>
            <Box display="flex" justifyContent="space-between">
                <ReachInput
                label="Min Reach"
                value={minReach}
                onChange={(newValue) => setMinReach(newValue)}
                />
                <ReachInput
                label="Max Reach"
                value={maxReach}
                onChange={(newValue) => setMaxReach(newValue)}
                />
            </Box>
            </FormControl>

      {/* Reach Period */}
      <FormControl fullWidth variant="outlined" size="small" margin="normal">
        <InputLabel>Reach Period</InputLabel>
        <Select
        label="Reach Period"
        value={reachPeriod}
        onChange={(e) => setReachPeriod(e.target.value)}
        >
        <MenuItem value="hour">Per Hour</MenuItem>
        <MenuItem value="day">Per Day</MenuItem>
        <MenuItem value="week">Per Week</MenuItem>
        <MenuItem value="month">Per Month</MenuItem>
        </Select>
    </FormControl>
      <CollapseButton onClick={toggleCollapse}>
        {!isCollapsed ?
          <ExpandLessIcon /> : <ExpandMoreIcon />}
      </CollapseButton>

      {/* Add your other filters here */}
    </StyledFilterSection>
  );
};

export default FilterSection;
