import React, { useCallback, useRef, useState } from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import styled from "styled-components";

const GalleryContainer = styled.div`
  max-height: 600px;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledCarousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: contain;
`;


const StyledThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allow thumbnails to wrap to a new line */
`;

const StyledThumbnailImage = styled.img`
  width: 100px;
  height: auto;
  object-fit: cover;
  margin: 5px; /* Add some spacing between thumbnails */
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
`;

function ImageCarousel({ imageUrls }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselInner = useRef(null);

  const changeSlide = useCallback(
    (position) => {
      setCurrentSlide(position);
    },
    []
  );

  return (
    <GalleryContainer>
      <MDBContainer className="mt-5">
        <StyledCarousel>
          <StyledImage src={imageUrls[currentSlide]} alt={`Slide ${currentSlide + 1}`} />

          <StyledThumbnailContainer>
            {imageUrls.map((imageUrl, index) => (
              <StyledThumbnailImage
                key={index}
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                active={currentSlide === index}
                onClick={() => changeSlide(index)}
              />
            ))}
          </StyledThumbnailContainer>
        </StyledCarousel>
      </MDBContainer>
    </GalleryContainer>
  );
}

export default ImageCarousel;
